import React from "react";
import Loading from "../../components/Loading";

const TableRow = ({
  row,
  columns,
  addVoterToList,
  fetchingAddVoterToList,
  index,
}) => {
  return (
    <>
      <tr className="border-b h-[52px] transition-colors relative">
        {columns
          .filter((col) => col.isActive)
          .map((col, dataIndex) => (
            <td
              key={`${dataIndex}`}
              className={`text-start text-sm lg:text-base first:pl-4 ${
                col.className ? col.className : ""
              }`}
            >
              {row[col.key]}
            </td>
          ))}

        <td className="voter-id-lookup-add-to-list text-center h-[52px] flex items-center right-0 !p-0 bg-white border-r border-b border-l sticky whitespace-nowrap">
          {fetchingAddVoterToList === row.voter_id && (
            <div className="absolute top-1/2 -translate-x-1/2 left-1/2 -translate-y-1/2">
              <Loading size={20} />
            </div>
          )}

          <button
            disabled={fetchingAddVoterToList || row.isDisabled}
            onClick={() => addVoterToList(row.voter_id)}
            id={index === 0 ? "voter-id-add-to-list" : ""}
            className={`h-6 text-sm text-[15px] disabled:opacity-70 font-bold w-full ${
              fetchingAddVoterToList === row.voter_id &&
              "!opacity-0 pointer-events-none"
            }`}
          >
            Add Voter
          </button>
        </td>
      </tr>
    </>
  );
};

export default TableRow;
