import React, { useState, useEffect, useRef } from "react";
import Ascending from "../../svg/Ascending";
import Descending from "../../svg/Descending";

const TableHead = ({ columns, setColumns, sort, setSort, isFetchingData }) => {
  const updateSort = (column) => {
    if (isFetchingData) return;

    let newSort = {};

    if (sort.column !== column) newSort = { column: column, order: "desc" };
    else if (sort.order === "asc") newSort = { ...sort, order: "desc" };
    else newSort = { ...sort, order: "asc" };

    setSort(newSort);
  };

  const [dragCol, setDragCol] = useState(null);
  const [colStyle, setColStyle] = useState({ x: 0, y: 0, width: 0 });

  const draggableCol = useRef();

  const dragTimeoutRef = useRef(null);

  const handleMouseDown = (e, column, key) => {
    dragTimeoutRef.current = setTimeout(() => {
      const index = columns
        .filter((col) => col.isActive)
        .reduce((acc, curr, i) => {
          if (curr.key === key) return i;
          return acc;
        }, null);

      setDragCol(column);

      const cols = document.querySelectorAll(`.voter-id-lookup-col`);
      const col = cols[index];

      setColStyle({
        x: e.clientX - col.getBoundingClientRect().width / 2,
        y: e.clientY - col.getBoundingClientRect().height / 2,
        width: col.getBoundingClientRect().width,
        height: col.getBoundingClientRect().height,
      });
    }, 200);
  };

  const handleMouseMove = (e) => {
    if (!dragCol) return;

    const newColStyle = {
      ...colStyle,
      x: e.clientX - colStyle.width / 2,
      y: e.clientY - colStyle.height / 2,
    };
    setColStyle(newColStyle);

    const cols = [...document.querySelectorAll(`.voter-id-lookup-col`)];

    let activeColIndex = null;

    for (let i in cols) {
      if (Math.abs(e.clientX - cols[i]?.getBoundingClientRect()?.x) < 30) {
        activeColIndex = i;
        break;
      }
    }

    if (activeColIndex !== null) {
      setColumns((prev) => {
        const toBeReplacedWith = prev.filter((c) => c.isActive)[activeColIndex];

        let oldIndex = null;
        let toBeReplacedWithIndex = null;

        prev.forEach((col, i) => {
          if (col.key === dragCol.key) oldIndex = i;
          if (col.key === toBeReplacedWith.key) toBeReplacedWithIndex = i;
        });

        const prevMemory = JSON.parse(localStorage.getItem("my-list-memory"));

        const newCols = [...prev];

        [newCols[toBeReplacedWithIndex], newCols[oldIndex]] = [
          newCols[oldIndex],
          newCols[toBeReplacedWithIndex],
        ];

        localStorage.setItem(
          "voter-id-lookup-memory",
          JSON.stringify({ ...prevMemory, columns: newCols })
        );

        return newCols;
      });
    }
  };

  const handleMouseUp = () => {
    if (dragTimeoutRef.current) clearTimeout(dragTimeoutRef.current);
    setDragCol(null);
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, [dragCol]);

  useEffect(() => {
    window.addEventListener("mouseup", handleMouseUp);
    return () => window.removeEventListener("mouseup", handleMouseUp);
  }, []);

  return (
    <>
      {dragCol !== null && (
        <>
          <div className="z-[51] fixed inset-0 bg-black/30"></div>

          <div
            ref={draggableCol}
            style={{
              translate: `${colStyle.x}px ${colStyle.y}px`,
              width: `${colStyle.width}px`,
              height: `${colStyle.height}px`,
            }}
            className={`text-start font-semibold transition-colors cursor-pointer select-none text-sm lg:text-base absolute flex items-center bg-[white] z-[52] top-[0px] left-0 px-2 ${
              sort.column === dragCol.key ? "text-[#0e7490]" : ""
            }`}
          >
            <div className="flex gap-[5px] sm:gap-2 h-10 items-center rounded-md w-max">
              <span>{dragCol.text}</span>
              <div className="flex justify-center w-[17px] lg:w-5 translate-y-[0px]">
                {sort.column !== dragCol.key && (
                  <img src="/flip.svg" alt="flip" className="w-[17px] lg:w-5" />
                )}
                {sort.column === dragCol.key &&
                  (sort.order === "asc" ? (
                    <img
                      src="/ascending.svg"
                      alt="..."
                      className="w-[15px] lg:w-[18px]"
                    />
                  ) : (
                    <img
                      src="/descending.svg"
                      alt="..."
                      className="w-[15px] lg:w-[18px]"
                    />
                  ))}
              </div>
            </div>
          </div>
        </>
      )}

      <thead>
        <tr className="border-b h-[52px] rounded-t-lg">
          {columns
            .filter((c) => c.isActive)
            .map((col, index) => (
              <th
                key={col.key}
                onMouseDown={(e) => handleMouseDown(e, col, col.key)}
                onClick={() => updateSort(col.key)}
                className={`text-start font-semibold transition-all cursor-pointer select-none text-sm lg:text-base first:pl-4 voter-id-lookup-col ${
                  isFetchingData
                    ? "bg-[#e5e7eb] opacity-60 hover:bg-[#e5e7eb] cursor-default"
                    : sort.column === col.key
                    ? "hover:bg-[#ecfeff] text-[#0e7490]"
                    : "hover:bg-[#F9F9FA]"
                }`}
              >
                <div className="flex gap-[5px] sm:gap-2 h-10 items-center rounded-md w-max">
                  <span>{col.text}</span>
                  <div
                    id={index === 0 ? "data-table-sort-first" : ""}
                    className="flex justify-center w-[17px] lg:w-5"
                  >
                    {sort.column !== col.key && (
                      <img
                        src="/flip.svg"
                        alt="flip"
                        className="w-[17px] lg:w-5"
                      />
                    )}
                    {sort.column === col.key &&
                      (sort.order === "asc" ? (
                        <Ascending className="w-[15px] lg:w-[18px]" />
                      ) : (
                        <Descending className="w-[15px] lg:w-[18px]" />
                      ))}
                  </div>
                </div>
              </th>
            ))}

          <th className="text-center min-w-[44px]">
            {/* hidden */}
            <button className="w-[36px] border p-1 rounded-lg hover:bg-[#F9F9FA] transition-colors pointer-events-none opacity-0 invisible">
              <img src="/plus.svg" alt="add" className="w-full" />
            </button>
          </th>
        </tr>
      </thead>
    </>
  );
};

export default TableHead;
