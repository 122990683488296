import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Dropdown from "../Dropdown";

const NUMBER_OF_PAGINATIONS = 3;

const Pagination = ({
  totalPages,
  fetchFilteredList,
  isFetchingData = false,
  className = "",
  perPage,
  setPerPage,
}) => {
  const [perPageDropdownActive, setPerPageDropdownActive] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const pageInUrl = searchParams.get("page") ?? 1;
  const page = isNaN(+pageInUrl) ? 1 : +pageInUrl;

  const actualNumberOfPaginations =
    NUMBER_OF_PAGINATIONS > totalPages ? totalPages : NUMBER_OF_PAGINATIONS;

  const nextPage = () => {
    if (page < totalPages) moveToPage(page + 1);
  };

  const previousPage = () => {
    if (page - 1 > 0) moveToPage(page - 1);
  };

  const moveToPage = (toPage) => {
    if (toPage === page) return;
    if (isFetchingData) return;

    setSearchParams({ page: toPage });

    fetchFilteredList(toPage);
  };

  const VALUE = Math.ceil((actualNumberOfPaginations - 1) / 2);

  const isAtStart = page <= VALUE;
  const isAtEnd = page >= totalPages - VALUE + 1;

  return (
    <div className={`flex flex-col items-center gap-4 ${className}`}>
      {totalPages !== 0 && (
        <div className="flex gap-2 flex-wrap">
          {/* <button
        onClick={() => {
          if (page === 1) return;
          moveToPage(1);
        }}
        disabled={isFetchingData || page === 1}
        className="flex items-center font-semibold gap-3 border h-11 px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px] disabled:bg-[#e5e7eb] disabled:opacity-60"
      >
        First Page
      </button> */}
          <button
            onClick={previousPage}
            disabled={page - 1 <= 0 || isFetchingData}
            className="flex items-center font-semibold gap-3 border h-11 px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px] disabled:bg-[#e5e7eb] disabled:opacity-60"
          >
            Previous
          </button>
          {new Array(actualNumberOfPaginations).fill(0).map((_, i) => {
            let paginationNumber = page + i - VALUE;

            if (isAtStart) paginationNumber = i + 1;
            if (isAtEnd)
              paginationNumber = totalPages - actualNumberOfPaginations + i + 1;

            return (
              <button
                key={i}
                disabled={isFetchingData}
                className={`h-11 min-w-11 px-3 border rounded-md disabled:bg-[#e5e7eb] disabled:opacity-60 ${
                  page === paginationNumber && "font-semibold"
                }`}
                onClick={() => moveToPage(paginationNumber)}
              >
                {paginationNumber}
              </button>
            );
          })}
          <button
            onClick={nextPage}
            disabled={page >= totalPages || isFetchingData}
            className="flex items-center font-semibold gap-3 border h-11 px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px] disabled:bg-[#e5e7eb] disabled:opacity-60"
          >
            Next
          </button>
          {/* <button
        disabled={isFetchingData || page === totalPages || totalPages === 0}
        onClick={() => {
          if (page === totalPages || totalPages === 0) return;
          moveToPage(totalPages);
        }}
        className="flex items-center font-semibold gap-3 border h-11 px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px] disabled:bg-[#e5e7eb] disabled:opacity-60"
      >
        Last Page ({totalPages})
      </button> */}
        </div>
      )}

      <div className="flex gap-6 items-center">
        {totalPages !== 0 && (
          <div className="font-medium text-lg">
            Page <span className="font-bold">{page}</span> of{" "}
            <span className="font-bold">{totalPages}</span>
          </div>
        )}

        <div className="flex gap-2 items-center">
          <span className="text-lg font-medium">Per Page: </span>

          <div className="rounded-md border pl-2 pr-1 py-1 flex justify-center items-center text-lg font-medium relative">
            <button
              className="flex items-center gap-1"
              onClick={() => setPerPageDropdownActive((prev) => !prev)}
            >
              <span>{perPage}</span>
              <img src="/chevron-down.svg" alt="" />
            </button>

            {perPageDropdownActive && (
              <ul className="absolute top-full right-0 bg-white translate-y-1 border rounded-md min-w-full overflow-hidden">
                <li
                  onClick={() => {
                    setPerPage(10);
                    setPerPageDropdownActive(false);
                  }}
                  className="px-2 py-0.5 text-base cursor-pointer hover:bg-[#eee]"
                >
                  10
                </li>
                <li
                  onClick={() => {
                    setPerPage(25);
                    setPerPageDropdownActive(false);
                  }}
                  className="px-2 py-0.5 text-base cursor-pointer hover:bg-[#eee]"
                >
                  25
                </li>
                <li
                  onClick={() => {
                    setPerPage(50);
                    setPerPageDropdownActive(false);
                  }}
                  className="px-2 py-0.5 text-base cursor-pointer hover:bg-[#eee]"
                >
                  50
                </li>
                <li
                  onClick={() => {
                    setPerPage(100);
                    setPerPageDropdownActive(false);
                  }}
                  className="px-2 py-0.5 text-base cursor-pointer hover:bg-[#eee]"
                >
                  100
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
