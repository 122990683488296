import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";

const TableRow = ({
  row,
  columns,
  selectedRows,
  setSelectedRows,
  deleteVoters,
  isActionsLoading,
  fetchVoters,
}) => {
  const [dropdownIsExpanded, setDropdownIsExpanded] = useState(false);

  useEffect(() => {
    if (!isActionsLoading) setDropdownIsExpanded(false);
  }, [isActionsLoading]);

  const isSelected = selectedRows.includes(row.id);

  const toggleSelectRow = () => {
    setSelectedRows((prev) => {
      if (!isSelected) return [...prev, row.id];
      return prev.filter((voterId) => voterId !== row.id);
    });
  };

  return (
    <>
      <tr
        className={`border-b h-[52px] transition-colors relative overflow-visible ${
          isSelected ? "bg-[#F5F5F4]" : "hover:bg-[#F9F9FA]"
        }`}
      >
        <td className="min-w-[40px]">
          <div className="flex justify-center text-sm lg:text-base">
            <input
              type="checkbox"
              className="checkbox checkbox-black"
              checked={isSelected}
              onChange={toggleSelectRow}
            />
          </div>
        </td>
        {columns
          .filter((col) => col.isActive)
          .map((col, dataIndex) => (
            <td
              key={`${dataIndex}`}
              className={`text-start text-sm lg:text-base ${
                col.className ? col.className : ""
              }`}
            >
              {col.key === "voted"
                ? row[col.key] === 1
                  ? "Voted"
                  : "Not Voted"
                : row[col.key]}
            </td>
          ))}

        {/* actions */}
        <td
          className={`my-list-action-btn text-center h-[52px] sticky right-0 !p-0 bg-white border-r border-b ${
            dropdownIsExpanded ? "z-20" : "z-0"
          }`}
        >
          <div className={`text-sm lg:text-[15px] relative`}>
            <button onClick={() => setDropdownIsExpanded((prev) => !prev)}>
              <div className="h-[52px] flex items-center justify-center min-w-[36px]">
                <img src="/dots.svg" alt="menu" className="w-[24px]" />
              </div>
            </button>

            <div
              onClick={() => setDropdownIsExpanded(false)}
              className={`fixed inset-0 ${
                dropdownIsExpanded
                  ? "pointer-events-auto"
                  : "pointer-events-none"
              }`}
            ></div>

            <ul
              className={`absolute w-max bg-white border rounded-[8px] p-1 transition-all translate-x-[-77%] translate-y-[-10px] ${
                dropdownIsExpanded
                  ? "opacity-100 translate-y-1"
                  : "pointer-events-none opacity-0 translate-y-0"
              }`}
            >
              <li className="h-8 lg:h-9 flex items-center px-2 rounded-[7px] gap-2 font-semibold">
                Actions
              </li>
              <div className="relative">
                <div
                  className={`absolute inset-0 flex justify-center items-center ${
                    isActionsLoading
                      ? ""
                      : "opacity-0 pointer-events-none invisible"
                  }}`}
                >
                  <Loading size={24} />
                </div>

                <div
                  className={
                    isActionsLoading
                      ? "opacity-0 pointer-events-none invisible"
                      : ""
                  }
                >
                  {row.voter_id === "missing" && selectedRows.length < 2 && (
                    <li
                      aria-label="list-option"
                      onClick={() => fetchVoters(row)}
                      className="hover:bg-[#F9F9FA] transition-colors cursor-pointer h-8 lg:h-9 flex items-center px-2 rounded-[7px] gap-2"
                    >
                      Find Voter ID
                    </li>
                  )}

                  <li
                    aria-label="list-option"
                    className="hover:bg-[#F9F9FA] transition-colors cursor-pointer h-8 lg:h-9 flex items-center px-2 rounded-[7px] gap-2 text-cherry"
                    onClick={() => deleteVoters(row.id)}
                  >
                    {selectedRows.length > 1 ? "Remove voters" : "Remove voter"}
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </td>
      </tr>
    </>
  );
};

export default TableRow;
