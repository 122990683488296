import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../components/Button";
import Input from "../../components/Input";

const Main = () => {
  const [email, setEmail] = useState("");
  const [buttonState, setButtonState] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    setButtonState("loading");

    e.preventDefault();

    try {
      const response = await axios.post(
        process.env.REACT_APP_RESET_PASSWORD_API_URL,
        {
          purpose: "send-reset-link",
          email: email.toLowerCase().trim(),
        }
      );

      setEmail("");
      toast.success("Email sent successfully!");
      setButtonState("success");
    } catch (error) {
      console.log(error);
      console.error("There was an error submitting the data!", error);
      toast.error(error.response.data);
      setButtonState("");
    }
  };

  return (
    <div className="w-full flex justify-center items-center flex-1 font-mont min-h-[500px] lg:min-h-[550px]">
      <div
        className="w-[90%] max-w-[500px] rounded-lg flex flex-col items-center lg:translate-y-[-3vh]"
        style={{
          boxShadow:
            "-1px 0px 7px -1px #00000010, 0px -1px 7px -1px #00000010, 1px 0px 7px -1px #00000010, 0px 1px 7px -1px #00000010",
        }}
      >
        <div className="w-full border-b px-4 py-4 font-semibold text-xl">
          Send Password Reset Link
        </div>

        <div className="w-full flex flex-col gap-4 leading-[1.5] border-b py-4">
          <p className="px-4">
            Please enter your email address below and a reset password link will
            be sent to you email.
          </p>

          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col gap-[18px]"
          >
            <Input
              type="email"
              name="email"
              placeholder="Email Address"
              value={email}
              className="mx-4"
              onChange={handleChange}
            />

            <div className="w-full h-[1px] bg-[#e5e7eb]"></div>

            <div className="w-full px-4 flex justify-end gap-2">
              <Link
                to="/login"
                className="flex items-center h-9 rounded-md bg-[#e3e4e9] text-[#45484e] px-[22px] font-semibold"
              >
                Cancel
              </Link>

              <Button
                type="submit"
                state={buttonState}
                disabled={
                  buttonState === "loading" ||
                  buttonState === "success" ||
                  email === ""
                }
              >
                Send Reset Link
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Main;
