import React, { useState } from "react";
import NameStep from "./NameStep";
import MatchUserStep from "./MatchUserStep";
import WaitingScreen from "./WaitingScreen";
import SetupPassword from "./SetupPassword";
import RoleStep from "./RoleStep";
import Report from "./Report";
import InputPhoneStep from "./InputPhoneStep";
import VerifyPhoneStep from "./VerifyPhoneStep";

const defaultFormData = {
  first_name: "",
  middle_name: "",
  last_name: "",
};

export const STEPS = {
  INPUT_PHONE_NUMBER: "input-phone-number",
  VERIFY_PHONE_NUMBER: "verify-phone-number",
  INPUT_FULL_NAME: "input-full-name",
  CANT_FIND_VOTER_ID: "cant-find-voter-id",
  MATCH_USER: "match-user",
  SELECT_ROLE: "select-role",
  COMPLETE_SIGNUP: "complete-signup",
};

const Main = () => {
  const [step, setStep] = useState(STEPS.INPUT_PHONE_NUMBER);
  const [isWaiting, setIsWaiting] = useState(null);
  const [fetchedVoters, setFetchedVoters] = useState(null);
  const [formData, setFormData] = useState(defaultFormData);
  const [previousFormData, setPreviousFormData] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const restart = () => {
    setFetchedVoters(null);
    setStep(1);
    setFormData(defaultFormData);
  };

  return (
    <div className="w-full flex justify-center items-center flex-1 font-mont min-h-[500px] lg:min-h-[550px] mt-[90px]">
      <div
        className={`w-[90%] rounded-lg flex flex-col items-center lg:translate-y-[-3vh] ${
          step === 2 ? "max-w-[800px]" : "max-w-[500px]"
        }`}
        style={{
          boxShadow:
            "-1px 0px 7px -1px #00000010, 0px -1px 7px -1px #00000010, 1px 0px 7px -1px #00000010, 0px 1px 7px -1px #00000010",
        }}
      >
        <div className="w-full border-b px-4 py-4 font-semibold text-xl text-center">
          {step === 4 && isWaiting === true
            ? "Account Waiting for Review"
            : "Account Setup"}
        </div>

        <div className="w-full flex flex-col gap-4 leading-[1.5] border-b py-4">
          {step === STEPS.CANT_FIND_VOTER_ID && (
            <Report setStep={setStep} previousFormData={previousFormData} />
          )}

          {step === STEPS.INPUT_PHONE_NUMBER && (
            <InputPhoneStep setStep={setStep} />
          )}

          {/* {step === "verify-phone-number" && (
            <VerifyPhoneStep setStep={setStep} />
          )} */}

          {step === STEPS.INPUT_FULL_NAME && (
            <NameStep
              formData={formData}
              handleChange={handleChange}
              setStep={setStep}
              setFetchedVoters={setFetchedVoters}
              setPreviousFormData={setPreviousFormData}
            />
          )}

          {step === STEPS.MATCH_USER && (
            <MatchUserStep
              restart={restart}
              fetchedVoters={fetchedVoters}
              setIsWaiting={setIsWaiting}
              setStep={setStep}
            />
          )}

          {/* {step === STEPS.SELECT_ROLE && <RoleStep setStep={setStep} />} */}

          {step === STEPS.COMPLETE_SIGNUP && isWaiting === true && (
            <WaitingScreen />
          )}

          {step === STEPS.COMPLETE_SIGNUP && isWaiting === false && (
            <SetupPassword />
          )}
        </div>
      </div>
    </div>
  );
};

export default Main;
