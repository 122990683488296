import React, { useEffect, useState } from "react";
import Header1 from "../../layout/Header1";
import Main from "./Main";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import axios from "axios";
import PageNotFound from "../../components/PageNotFound";

const SetPassword = () => {
  const [pageExists, setPageExists] = useState(null);

  const { token } = useParams();

  const authenticatePage = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_RESET_PASSWORD_API_URL,
        {
          purpose: "verify-reset-token",
          token,
        }
      );

      if (response.data === "Token is valid.") setPageExists(true);
      else setPageExists(false);
    } catch (error) {
      setPageExists(false);
      console.error("There was an error submitting the data!", error);
    }
  };

  useEffect(() => {
    authenticatePage();
  }, []);

  if (pageExists === null) {
    return (
      <>
        <Header1 />
        <div className="flex justify-center items-center flex-1">
          <Loading />
        </div>
      </>
    );
  }

  if (pageExists === true) {
    return (
      <>
        <Header1 />
        <Main />
      </>
    );
  }

  return <PageNotFound />;
};

export default SetPassword;
