import React from "react";

const About = () => {
  return (
    <div
      className="bg-[#0A293D] flex justify-center py-14 md:py-20"
      id="about-us"
    >
      <div className="w-[90%] max-w-[970px] flex flex-col md:flex-row justify-between items-center gap-12">
        <img
          src="/vote.png"
          alt="vote"
          className="max-w-[250px] w-[80%]"
          width={266}
          height={257}
        />

        <div className="text-peach font-fira max-w-[540px] space-y-3 md:space-y-5 lg:space-y-7 tracking-[0.6px] text-center md:text-start">
          <h2 className="text-[28px] xs:text-[32px] sm:text-[36px] md:text-[42px] font-black leading-[1.1]">
            ABOUT FIRED UP TO VOTE
          </h2>
          <p className="text-lg sm:text-xl font-black leading-[1.65]">
            FIRED UP TO VOTE is a peer-to-peer get-out-the-vote platform that empowers concerned, engaged citizens in Harris County to use their personal networks and spheres of influence to inform and mobilize their connections to VOTE and impact election outcomes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
