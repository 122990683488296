import React, { useState } from "react";
import Loading from "../../components/Loading";
import Check from "../../components/svg/Check";
import Cross from "../../components/svg/Cross";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Input from "../../components/Input";

const CAPITAL_REGEX = /[A-Z]/;
const NUMBER_REGEX = /[0-9]/;
const SPECIAL_CHAR_REGEX = /[!@#$%^&*]/;

const defaultFormData = {
  newPassword: { value: "", isValid: false, message: "" },
  confirmNewPassword: { value: "", isValid: false, message: "" },
};

const SetupPassword = () => {
  const [formData, setFormData] = useState(defaultFormData);
  const [buttonState, setButtonState] = useState(null);

  const { token } = useParams();

  const isValidated = Object.keys(formData).every(
    (name) => formData[name].isValid
  );

  const navigate = useNavigate();

  const validateInput = (name, value, formData) => {
    if (name === "newPassword") {
      if (!CAPITAL_REGEX.test(value))
        return {
          isValid: false,
          message: "Password must contain at least one capital letter",
        };

      if (!NUMBER_REGEX.test(value))
        return {
          isValid: false,
          message: "Password must contain at least one number",
        };

      if (!SPECIAL_CHAR_REGEX.test(value))
        return {
          isValid: false,
          message: "Password must contain at least one special character",
        };

      if (value.length < 8)
        return {
          isValid: false,
          message: "Password must be at least 8 characters long",
        };

      return { isValid: true, message: "" };
    } else if (name === "confirmNewPassword") {
      if (value === "" || value !== formData.newPassword.value)
        return { isValid: false, message: "Passwords do not match" };

      return { isValid: true, message: "" };
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const { isValid, message } = validateInput(name, value, formData);

    setFormData((formData) => ({
      ...formData,
      [name]: { isValid, value, message },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidated) return;

    setButtonState("loading");

    try {
      const response = await axios.post(
        process.env.REACT_APP_EMAIL_SIGNUP_API_URL,
        {
          purpose: "set_user_password",
          token,
          new_password: formData.newPassword.value,
        }
      );

      localStorage.setItem("jwt-token", response.data.token);
      toast.success("Password successfully set!");
      navigate("/dashboard");
    } catch (error) {
      console.error("There was an error submitting the data!", error);
      toast.error("Something went wrong");
    }

    setButtonState(null);
    setFormData(defaultFormData);
  };

  return (
    <form onSubmit={handleSubmit} className="w-full flex flex-col gap-3 px-5">
      <p className="text-sm leading-[1.4] mb-1">
        Password must be at least 8 characters long, include one capital letter,
        one number, and one special character (!@#$%^&*).
      </p>

      <Input
        variant="password"
        name="newPassword"
        placeholder={"Enter New Password"}
        onChange={handleChange}
        value={formData["newPassword"].value}
        message={formData["newPassword"].message}
        isValid={formData["newPassword"].isValid}
      />

      <Input
        variant="password"
        name="confirmNewPassword"
        placeholder="Confirm New Password"
        onChange={handleChange}
        value={formData["confirmNewPassword"].value}
        message={formData["confirmNewPassword"].message}
        isValid={formData["confirmNewPassword"].isValid}
      />

      {buttonState === "loading" ? (
        <div className="justify-center items-center  flex h-[44px] rounded-md bg-cherry text-white hover:bg-white hover:text-cherry transition-all duration-200 border border-cherry text- font-semibold">
          <Loading size={26} invert />
        </div>
      ) : (
        <button
          disabled={!isValidated}
          className="h-[44px] rounded-md bg-cherry text-white hover:bg-white hover:text-cherry transition-all duration-200 border border-cherry font-semibold disabled:hover:text-white disabled:hover:bg-cherry disabled:cursor-not-allowed disabled:opacity-70"
        >
          Submit
        </button>
      )}
    </form>
  );
};

export default SetupPassword;
