import React, { useEffect, useRef, useState } from "react";
import TableRow from "./TableRow";
import { useNavigate, useSearchParams } from "react-router-dom";
import TableHead from "./TableHead";
import axios from "axios";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";
import DataTableSteps from "../../components/steps/DataTableSteps";
import Pagination from "../../components/table/Pagination";
import ToggleColumns from "../../components/table/ToggleColumns";
import Filters from "../../components/table/Filters";
import AddVoters from "./AddVoters";
import { defaultColumns, defaultSort, tempData } from "./data";
import InCompleteRowSearchResult from "./InCompleteRowSearchResult";
import downloadAsCsv from "../../utils/downloadAsCsv";

const Table = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const pageInUrl = searchParams.get("page") ?? 1;
  const page = isNaN(+pageInUrl) ? 1 : +pageInUrl;

  const [isShowingTutorial, setIsShowingTutorial] = useState(false);

  const [filteredList, setFilteredList] = useState([]);

  const [sort, setSort] = useState(() => {
    const memory = JSON.parse(localStorage.getItem("my-list-memory"));
    return memory?.sort || defaultSort;
  });

  const [columns, setColumns] = useState(() => {
    const memory = JSON.parse(localStorage.getItem("my-list-memory"));
    return memory?.columns || defaultColumns;
  });

  const [perPage, setPerPage] = useState(() => {
    const memory = JSON.parse(localStorage.getItem("my-list-memory"));
    return memory?.perPage || 10;
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const [fetchedVoters, setFetchedVoters] = useState(null);

  const navigate = useNavigate();

  const [isFetchingData, setIsFetchingData] = useState(true);
  const [isActionsLoading, setIsActionsLoading] = useState(false);
  const [exportButtonState, setExportButtonState] = useState("");

  const resetColumns = () => {
    setColumns(
      columns.map((col) => ({
        ...col,
        filter: { ...col.filter, value: "" },
      }))
    );
  };

  const fetchVoters = async (row) => {
    setIsActionsLoading(true);

    try {
      const response = await axios.post(
        process.env.REACT_APP_EMAIL_SIGNUP_API_URL,
        {
          purpose: "submit_name",
          first_name: row.first_name || "",
          last_name: row.last_name || "",
        }
      );

      setFetchedVoters(response.data.matches);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong");
    }

    setIsActionsLoading(false);

    const lowerFirstName = (row.first_name || "").toLowerCase();
    const lowerLastName = (row.last_name || "").toLowerCase();

    localStorage.setItem("search_first_name", lowerFirstName);
    localStorage.setItem("search_last_name", lowerLastName);

    console.log("Stored in localStorage (lowercase):");
    console.log(
      "search_first_name:",
      localStorage.getItem("search_first_name")
    );
    console.log("search_last_name:", localStorage.getItem("search_last_name"));
  };

  const deleteVoter = async (id) => {
    setIsActionsLoading(true);

    let targetedRows = [id];
    if (selectedRows.includes(id)) targetedRows = selectedRows;

    const filters = getAppropriateFiltersFormat();

    console.log("filters:", filters);

    const params = {
      purpose: "delete_voter",
      page: page,
      per_page: perPage,
      sort: sort,
      filters: filters,
      voter_id_list: targetedRows,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_LOAD_DATA_TABLE_API_URL,
        params,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt-token")}`,
          },
        }
      );

      localStorage.setItem("jwt-token", response.data.token);
      setFilteredList(response.data.items);
      toast.success("Voter deleted!");
    } catch (error) {
      if (
        error.response.data === "Token has expired" ||
        error.response.data === "Unauthorized" ||
        error.response.data === "Invalid token"
      )
        navigate("/login");
      console.log(error);
    }

    setIsActionsLoading(false);
    setSelectedRows([]);
  };

  const apiSourceRef = useRef(null);

  const fetchFilteredList = async (page, shouldResetFilters = false) => {
    const controller = new AbortController();
    const { signal } = controller;
    apiSourceRef.current = controller;

    setIsFetchingData(true);

    const prevList = filteredList;
    setFilteredList([]);

    const filters = shouldResetFilters ? {} : getAppropriateFiltersFormat();

    try {
      const response = await axios.post(
        process.env.REACT_APP_LOAD_DATA_TABLE_API_URL,
        {
          page,
          per_page: perPage,
          sort,
          filters,
        },
        {
          signal,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt-token")}`,
          },
        }
      );

      localStorage.setItem("jwt-token", response.data.token);
      setFilteredList(response.data.items);
      setTotalPages(response.data.total_pages);
      setIsFetchingData(false);

      if (shouldResetFilters) resetColumns();
    } catch (error) {
      console.log(error);
      if (
        error.response?.data === "Token has expired" ||
        error.response?.data === "Unauthorized" ||
        error.response?.data === "Invalid token"
      )
        navigate("/login");
      if (!error instanceof axios.CanceledError) {
        setFilteredList(prevList);
        console.error("There was an error submitting the data!", error);
        setIsFetchingData(false);
      }
    }
  };

  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      fetchFilteredList(page);
    } else {
      setSearchParams({ page: 1 });
      fetchFilteredList(1);
    }

    return () => {
      if (apiSourceRef.current) apiSourceRef.current.abort();
    };
  }, [...columns.map((c) => c.filter.value), sort, perPage]);

  const firstRenderRef2 = useRef(true);

  useEffect(() => {
    if (firstRenderRef2.current) {
      firstRenderRef2.current = false;
    } else {
      localStorage.setItem(
        "my-list-memory",
        JSON.stringify({ columns, sort, perPage })
      );
    }
  }, [
    ...columns.map((c) => c.isActive),
    ...columns.map((c) => c.filter.value),
    sort,
    perPage,
  ]);

  function getAppropriateFiltersFormat() {
    const filters = {};

    columns.forEach((col) => {
      if (col.key === "voted" && col.filter.value !== "")
        filters[col.key] = col.filter.value === "Voted" ? "1" : "0";
      else filters[col.key] = col.filter.value.toLowerCase().trim();
    });

    return filters;
  }

  const addedTempData = useRef(false);

  const exportTable = async () => {
    setExportButtonState("loading");

    try {
      const response = await axios.post(
        process.env.REACT_APP_LOAD_DATA_TABLE_API_URL,
        { purpose: "export" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt-token")}`,
          },
        }
      );

      if (response.data.items.export_data) {
        downloadAsCsv(response.data.items.export_data);
        toast.success("Data exported successfully!");
        setExportButtonState(""); // Reset the button state
      } else {
        toast.error("Failed to export data!");
        setExportButtonState("");
      }
    } catch (err) {
      toast.error("Something went wrong");
      console.log(err);
      setExportButtonState("");
    }
  };

  const [filtersKey, setFiltersKey] = useState(0);

  const resetFilters = () => {
    localStorage.removeItem("voter-id-lookup-memory");

    resetColumns();
    setPerPage(10);
    setSort(defaultSort);

    setFiltersKey((prev) => (prev += 1));
  };

  return (
    <>
      <div className="flex flex-col w-full gap-3 sm:gap-4">
        <div className="flex justify-between items-end gap-2">
          <DataTableSteps
            isShowing={isShowingTutorial}
            setIsShowing={setIsShowingTutorial}
            setFilteredList={setFilteredList}
            addedTempData={addedTempData}
          />

          {fetchedVoters && (
            <div className="fixed z-[51] inset-0 isolate flex justify-center items-center">
              <div
                className="absolute inset-0 bg-black bg-opacity-30 cursor-pointer -z-10"
                onClick={() => setFetchedVoters(null)}
              ></div>

              <InCompleteRowSearchResult
                setFilteredList={setFilteredList}
                setTotalPages={setTotalPages}
                close={() => setFetchedVoters(null)}
                fetchedVoters={fetchedVoters}
                filters={getAppropriateFiltersFormat()}
                currentPage={page}
                perPage={perPage}
                currentSort={sort}
              />
            </div>
          )}

          <div className="flex flex-col sm:flex-row items-end justify-end gap-2 flex-wrap flex-grow">
            <button
              className="flex items-center font-semibold gap-1 md:gap-3 border h-11 px-2 md:px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px]"
              onClick={resetFilters}
            >
              Reset Filters
            </button>

            <button
              id="export-btn"
              disabled={exportButtonState !== ""}
              onClick={exportTable}
              className="flex items-center font-semibold gap-1 md:gap-3 border h-11 px-2 md:px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px] relative disabled:hover:bg-transparent disabled:opacity-60"
            >
              {exportButtonState === "loading" && (
                <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                  <Loading size={22} />
                </div>
              )}

              <span
                className={
                  exportButtonState === "loading" ? "opacity-0" : "opacity-100"
                }
              >
                Print my list
              </span>
            </button>

            <button
              disabled={isFetchingData}
              className="flex items-center font-semibold gap-1 md:gap-3 border h-11 px-2 md:px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px]"
              onClick={() => {
                setIsShowingTutorial(true);
                setFilteredList((prev) => {
                  if (prev.length > 0) return prev;
                  addedTempData.current = true;
                  return tempData;
                });
              }}
            >
              Page Helper
            </button>

            <AddVoters
              setFilteredList={setFilteredList}
              setTotalPages={setTotalPages}
              showButton={!(filteredList.length === 0 && !isFetchingData)}
              onImportSuccess={() => fetchFilteredList(1, true)}
              isOpen={isImportModalOpen}
              setIsOpen={setIsImportModalOpen}
              currentPage={page}
              perPage={perPage}
              currentSort={sort}
              currentFilters={getAppropriateFiltersFormat()}
            />

            <ToggleColumns
              id="data-toggle-columns-button"
              columns={columns}
              setColumns={setColumns}
              sort={sort}
              setSort={setSort}
            />
          </div>
        </div>

        <div className="overflow-x-auto overflow-y-hidden w-full rounded-md pb-[112px] sm:pb-[140px]">
          <table
            id="my-list-table"
            className="w-full [&_td]:px-2 [&_th]:px-2 border border-b-0"
          >
            <TableHead
              columns={columns}
              setColumns={setColumns}
              sort={sort}
              setSort={setSort}
            />

            <tbody>
              <Filters
                key={filtersKey}
                columns={columns}
                setColumns={setColumns}
                filteredList={filteredList}
                setSelectedRows={setSelectedRows}
                allRowsSelected={
                  filteredList.length !== 0 &&
                  filteredList.every((row) => selectedRows.includes(row.id))
                }
                selectAllRows
              />

              {filteredList.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  row={row}
                  columns={columns}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  deleteVoters={deleteVoter}
                  isActionsLoading={isActionsLoading}
                  fetchVoters={fetchVoters}
                />
              ))}
            </tbody>
          </table>
        </div>

        {isFetchingData && (
          <div className="flex items-center justify-center my-6 -translate-y-[112px] sm:-translate-y-[140px]">
            <Loading />
          </div>
        )}

        {filteredList.length === 0 && !isFetchingData && (
          <div className="w-full h-[200px] flex justify-center items-center -translate-y-[112px] sm:-translate-y-[140px]">
            <button
              onClick={() => setIsImportModalOpen(true)}
              id="import-data"
              className="flex items-center font-semibold gap-4 py-3 px-5 hover:bg-[#F9F9FA] transition-colors rounded-md text-2xl border"
            >
              Add My Voter List
              <img src="/import.svg" alt="add" className="size-[36px]" />
            </button>
          </div>
        )}

        <Pagination
          totalPages={totalPages}
          fetchFilteredList={fetchFilteredList}
          perPage={perPage}
          setPerPage={setPerPage}
          className="-translate-y-[112px] sm:-translate-y-[140px]"
        />
      </div>
    </>
  );
};

export default Table;
