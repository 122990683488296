import React from "react";

const Statement = () => {
  return (
    <div id="statement" className="flex justify-center">
      <div className="max-w-[1200px] w-[90%] flex justify-center gap-16 md:gap-20 lg:gap-28 items-center py-[60px] sm:py-20 md:py-24 lg:py-[120px] flex-col md:flex-row">
        <div className="font-fira flex flex-col items-center gap-4 xl:gap-6 text-center w-full">
          <div className="mb-2 md:mb-4 xl:mb-6 md:h-[188px] flex justify-center items-center">
            <img
              src="/target.png"
              alt="target"
              className="w-[65%] sm:w-[80%] xl:w-full"
              width={188}
              height={188}
            />
          </div>
          <h6 className="text-cherry font-black text-[28px] sm:text-[32px] lg:text-[36px] leading-[1.1]">
            MISSION STATEMENT
          </h6>
          <p className="text-lg sm:text-xl font-medium leading-[1.75]">
            Fired Up To Vote's mission is to develop robust conservative
            voter participation in every election to consistently win elections
            of conservative candidates in Texas at the local, county, and state
            levels.
          </p>
        </div>

        <div className="hidden md:block min-w-[2px] h-[80%] bg-[#ccc]"></div>

        <div className="font-fira flex flex-col items-center gap-4 xl:gap-6 text-center w-full">
          <div className="mb-2 md:mb-4 xl:mb-6 md:h-[188px] flex justify-center items-center">
            <img
              src="/eye.png"
              alt="eye"
              className="w-[65%] sm:w-[80%] xl:w-full"
              width={259}
              height={134}
            />
          </div>
          <h6 className="text-cherry font-black text-[28px] sm:text-[32px] lg:text-[36px] leading-[1.1]">
            VISION STATEMENT
          </h6>
          <p className="text-lg sm:text-xl font-medium leading-[1.75]">
            To be the leading organization in the county for educating and
            mobilizing voters through relational outreach.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Statement;
