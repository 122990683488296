import React from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "./Button";

const PageNotFound = () => {
  const { pathname } = useLocation();

  const isInvalidResetPasswordLink =
    pathname.startsWith("/reset-password/") &&
    pathname.length > "/reset-password/".length;

  return isInvalidResetPasswordLink ? (
    <div className="flex flex-col w-[90%] mx-auto h-screen justify-center items-center gap-6 lg:gap-8 text-center">
      <div className="flex flex-col items-center gap-3">
        <h6 className="text-[30px] sm:text-[36px] font-bold">
          Reset Password Link Expired
        </h6>
        <p className="sm:text-lg">
          The reset password link you followed has expired or is invalid. Please
          request a new reset link.
        </p>
      </div>

      <Button
        to="/"
        className="h-[48px] sm:h-[52px] text-lg sm:text-xl mt-3 sm:mt-6"
      >
        Back to Home
      </Button>
    </div>
  ) : (
    <div className="flex flex-col w-[90%] mx-auto h-screen justify-center items-center gap-6 lg:gap-8 text-center">
      <h1 className="text-[70px] sm:text-[100px] lg:text-[124px] leading-[1] font-medium">
        404
      </h1>

      <div className="flex flex-col items-center">
        <h6 className="text-[26px] sm:text-[32px] font-bold">
          Page has expired
        </h6>
        <p className="sm:text-lg">
          Oops! The page you are looking for has expired. Please request a new
          one.
        </p>
      </div>

      <Button
        to="/"
        className="h-[48px] sm:h-[52px] text-lg sm:text-xl mt-3 sm:mt-6"
      >
        Back to Home
      </Button>
    </div>
  );
};

export default PageNotFound;
