import React from "react";
import Header1 from "../../layout/Header1";
import Main from "./Main";

const Login = ({setUserInfo}) => {
  return (
    <>
      <Header1 />
      <Main setUserInfo={setUserInfo}/>
    </>
  );
};

export default Login;
