import React, { useEffect, useState } from "react";
import Button from "../Button";
import OtpInput from "../OtpInput";
import { toast } from "react-toastify";
import axios from "axios";

const MissingEmail = ({ updateUserInfoAndSetNextStep, userInfo, close }) => {
  const [buttonState, setButtonState] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonState("loading");

    const form = document.getElementById("email-otp-form");

    const formData = new FormData(form);

    const first_digit = formData.get("first_digit");
    const second_digit = formData.get("second_digit");
    const third_digit = formData.get("third_digit");
    const fourth_digit = formData.get("fourth_digit");

    const code = first_digit + second_digit + third_digit + fourth_digit;

    try {
      const response = await axios.post(
        process.env.REACT_APP_CHECK_ACCOUNT_STANDARDS_API_URL,
        { purpose: "verify_email_code", code },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt-token")}`,
          },
        }
      );

      toast.success(response.data.message);
      updateUserInfoAndSetNextStep({ verified_email: true });
    } catch (err) {
      toast.error(err.response.data);
    }

    setButtonState("");
  };

  const sendCode = async (isResend = false) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_CHECK_ACCOUNT_STANDARDS_API_URL,
        { purpose: "send_email_code" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt-token")}`,
          },
        }
      );

      if (isResend && response.data.success) toast.success("Code resent");
    } catch (err) {
      console.error(err);
      if (isResend)
        toast.error("Something went wrong when trying to send code!");
    }
  };

  useEffect(() => {
    sendCode();
  }, []);

  return (
    <div className="max-w-[500px] w-[90%] bg-white rounded-lg">
      <div className="w-full border-b px-4 py-4 font-semibold text-2xl text-center">
        Email Verification
      </div>

      <div className="w-full flex flex-col gap-[18px] leading-[1.5] py-6">
        <div className="text-lg mb-4 text-center flex flex-col items-center">
          <p className="text-gray-600 max-w-[370px]">
            Enter the 4-digit verification code that was sent to your email address.
          </p>
        </div>
        <form onSubmit={handleSubmit} id="email-otp-form">
          <OtpInput formId="email-otp-form" />

          <div className="max-w-[260px] mx-auto mt-4">
            <Button
              state={buttonState}
              disabled={buttonState === "loading"}
              type="submit"
              className="w-full !h-11"
            >
              Verify Account
            </Button>
          </div>
        </form>
        <div className="mt-2 text-gray-600 mx-auto">
          Didn't receive code?{" "}
          <button
            onClick={() => sendCode(true)}
            className="font-medium text-cherry"
            href="#0"
          >
            Resend
          </button>
        </div>
      </div>

      <div className="w-full h-[1px] bg-[#e5e7eb]"></div>

      <div className="w-full p-4 flex justify-end gap-2">
        <button
          disabled={buttonState === "loading"}
          onClick={close}
          className="flex items-center h-9 rounded-md bg-[#e3e4e9] text-[#45484e] px-[22px] font-semibold disabled:opacity-70 disabled:cursor-not-allowed"
        >
          Skip
        </button>
      </div>
    </div>
  );
};

export default MissingEmail;