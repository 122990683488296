import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Header1 = () => {
  const [menuIsExpanded, setMenuIsExpanded] = useState(false);

  const [activeSection, setActiveSection] = useState(null);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const handleOffset = () => {
    if (pathname !== "/") {
      setActiveSection(null);
      return;
    }

    const supportUs = document.getElementById("support-us");
    const resources = document.getElementById("resources");
    const getInvolved = document.getElementById("get-involved");
    const aboutUs = document.getElementById("about-us");

    if (
      window.scrollY >= supportUs?.offsetTop - 91 ||
      window.innerHeight + window.scrollY >= document.body.offsetHeight
    ) {
      setActiveSection("support-us");
    } else if (window.scrollY >= resources?.offsetTop - 91) {
      setActiveSection("resources");
    } else if (window.scrollY >= getInvolved?.offsetTop - 91) {
      setActiveSection("get-involved");
    } else if (window.scrollY >= aboutUs?.offsetTop - 91) {
      setActiveSection("about-us");
    } else setActiveSection(null);
  };

  useEffect(() => {
    handleOffset();
    window.addEventListener("scroll", handleOffset);
    return () => window.removeEventListener("scroll", handleOffset);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("resize", handleOffset);
    return () => window.removeEventListener("resize", handleOffset);
  }, []);

  const scrollToSection = (e) => {
    e.preventDefault();

    const query = e.target.getAttribute("href");
    const element = document.querySelector(query);

    if (element) element.scrollIntoView({ behavior: "smooth" });
    else {
      navigate("/");
      setTimeout(() => {
        scrollToSection(e);
      }, 10);
    }
  };

  return (
    <header className="flex justify-center fixed top-0 w-full bg-white z-50 h-[90px]">
      <div className="w-[90%] max-w-[1400px] flex justify-between items-center">
        {pathname === "/" ? (
          <a href="#top">
            <img src="/logo.png" alt="logo" />
          </a>
        ) : (
          <Link to="/">
            <img src="/logo.png" alt="logo" />
          </Link>
        )}

        <button
          className="lg:hidden flex justify-center items-center z-10"
          onClick={() => setMenuIsExpanded((prev) => !prev)}
        >
          {menuIsExpanded ? (
            <img src="/x-mark.svg" alt="close" className="w-9" />
          ) : (
            <img src="/bars.svg" alt="open" className="w-9" />
          )}
        </button>

        <nav
          className={`gap-5 lg:gap-7 flex flex-col lg:flex-row fixed lg:static right-0 top-0 w-[280px] bg-peach h-full py-20 px-5 lg:p-0 lg:h-auto lg:bg-transparent lg:w-auto transition-transform duration-300 ${
            menuIsExpanded
              ? "translate-x-0"
              : "translate-x-full lg:translate-x-0"
          }`}
        >
          <a
            href="#about-us"
            onClick={(e) => {
              scrollToSection(e);
              setMenuIsExpanded(false);
            }}
            className={`uppercase text-sm font-fira font-medium ${
              activeSection === "about-us" ? "underline underline-offset-2" : ""
            }`}
          >
            About us
          </a>
          <a
            href="#get-involved"
            onClick={(e) => {
              scrollToSection(e);
              setMenuIsExpanded(false);
            }}
            className={`uppercase text-sm font-fira font-medium ${
              activeSection === "get-involved"
                ? "underline underline-offset-2"
                : ""
            }`}
          >
            Join Us
          </a>
          <a
            href="#resources"
            onClick={(e) => {
              scrollToSection(e);
              setMenuIsExpanded(false);
            }}
            className={`uppercase text-sm font-fira font-medium ${
              activeSection === "resources"
                ? "underline underline-offset-2"
                : ""
            }`}
          >
            Resources
          </a>
          <a
            href="#support-us"
            onClick={(e) => {
              scrollToSection(e);
              setMenuIsExpanded(false);
            }}
            className={`uppercase text-sm font-fira font-medium ${
              activeSection === "support-us"
                ? "underline underline-offset-2"
                : ""
            }`}
          >
            Support us
          </a>
          <Link
            to="/login"
            onClick={() => setMenuIsExpanded(false)}
            className={`uppercase text-sm font-fira font-medium ${
              pathname === "/login" ? "underline underline-offset-2" : ""
            }`}
          >
            Login
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header1;
