import { useEffect, useRef } from "react";

const useDebounce = (callback, dependencies, delay, ignoreInitialRender) => {
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current && ignoreInitialRender) {
      firstRenderRef.current = false;
      return;
    }

    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, dependencies);
};

export default useDebounce;
