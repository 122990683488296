import React, { useEffect, useState } from "react";
import ManualSearch from "./ManualSearch";
import Upload from "./Upload";
import ManualSearchResults from "./ManualSearchResults";

const AddVoters = ({
  setFilteredList,
  setTotalPages,
  showButton,
  isOpen,
  setIsOpen,
  currentPage,
  perPage,
  currentSort,
  currentFilters,
  onImportSuccess
}) => {
  const [activeTab, setActiveTab] = useState("upload"); // upload | manual
  const [modalType, setModalType] = useState("search"); // results | search
  const [fetchedVoters, setFetchedVoters] = useState(null);

  const close = () => setIsOpen(false);
  const back = () => {
    setModalType("search");
    setFetchedVoters(null);
  };

  useEffect(() => {
    if (isOpen) {
      setModalType("search");
      setActiveTab("upload");
    }

    return () => {
      setFetchedVoters(null);
      setModalType(null);
      setActiveTab(null);
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className="fixed z-[51] inset-0 isolate flex justify-center items-center">
          <div
            className="absolute inset-0 bg-black bg-opacity-30 cursor-pointer -z-10"
            onClick={close}
          ></div>

          {modalType === "search" && (
            <div className="w-[90%] max-w-[500px] bg-white flex flex-col rounded-xl relative">
              <div className="bg-[#FAFAFA] border-b rounded-t-xl px-4 xs:px-5 pt-5 space-y-2.5">
                <div className="flex justify-between items-start gap-6">
                  <div className="space-y-0.5">
                    <h6 className="text-xl xs:text-2xl text-[#323232] font-semibold font-mont !leading-[1.3]">
                      Search Names in the Database
                    </h6>
                    <p className="text-[#727272]">
                      Select your desired search method
                    </p>
                  </div>

                  <button
                    onClick={close}
                    className="bg-white border-black border size-8 min-w-8 rounded-lg flex justify-center items-center"
                  >
                    <img src="/cross.svg" className="size-4" />
                  </button>
                </div>

                <div className="flex gap-2 xs:gap-4">
                  <button
                    onClick={() => setActiveTab("upload")}
                    className={`py-3.5 px-2 relative before:absolute before:bottom-0 before:left-0 before:h-[2px] before:rounded-t-xl text-sm xs:text-base before:w-full transition-all before:transition-all font-semibold ${
                      activeTab === "upload"
                        ? "text-cherry before:bg-cherry"
                        : "text-[#969696] before:bg-[#969696] before:opacity-0 hover:before:opacity-50"
                    }`}
                  >
                    File Upload
                  </button>
                  <button
                    onClick={() => setActiveTab("manual")}
                    className={`py-3.5 px-2 relative before:absolute before:bottom-0 before:left-0 before:h-[2px] before:rounded-t-xl text-sm xs:text-base before:w-full transition-all before:transition-all font-semibold ${
                      activeTab === "manual"
                        ? "text-cherry before:bg-cherry"
                        : "text-[#969696] before:bg-[#969696] before:opacity-0 hover:before:opacity-50"
                    }`}
                  >
                    Manual Name Entry
                  </button>
                </div>
              </div>

              {activeTab === "upload" && (
                <Upload
                  setFilteredList={setFilteredList}
                  setTotalPages={setTotalPages}
                  close={close}
                  currentPage={currentPage}
                  perPage={perPage}
                  currentSort={currentSort}
                  currentFilters={currentFilters}
                  onImportSuccess={onImportSuccess}
                />
              )}
              {activeTab === "manual" && (
                <ManualSearch
                  setModalType={setModalType}
                  setFetchedVoters={setFetchedVoters}
                  close={close}
                />
              )}
            </div>
          )}

          {modalType === "results" && (
            <ManualSearchResults
              setFilteredList={setFilteredList}
              setTotalPages={setTotalPages}
              close={close}
              fetchedVoters={fetchedVoters}
              back={back}
              currentPage={currentPage}
              perPage={perPage}
              currentSort={currentSort}
              currentFilters={currentFilters}
            />
          )}
        </div>
      )}

      <button
        id="import-data"
        className={`flex items-center font-semibold gap-1 md:gap-3 border h-11 px-2 md:px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px] ${
          !showButton && "hidden"
        }`}
        onClick={() => setIsOpen(true)}
      >
        Add My Voter List
        <img
          src="/import.svg"
          alt="add"
          className="size-[20px] md:size-[22px]"
        />
      </button>
    </>
  );
};

export default AddVoters;