import React, { useState } from "react";
import useDebounce from "../../hooks/useDebounce";

const DropdownFilter = ({ updateFilters, colKey, values, value, debounce }) => {
  const [dropdownValue, setDropdownValue] = useState(value);
  const [isShowingDropdown, setIsShowingDropdown] = useState(false);

  useDebounce(
    () => {
      updateFilters(colKey, dropdownValue);
    },
    [dropdownValue],
    debounce ? 500 : 0,
    true
  );

  return (
    <td
      className={`h-[52px] py-1.5 first:pl-4 filter filter-dropdown filter-${colKey}`}
    >
      <div className="h-full w-max">
        <div className="h-full w-max relative">
          <button
            onClick={() => setIsShowingDropdown((prev) => !prev)}
            className={`relative flex justify-between items-center h-full border rounded-md text-[#999] px-2 lg:px-3 w-full text-[13px] lg:text-[15px] ${
              isShowingDropdown ? "z-20" : "z-0"
            }`}
          >
            <span className="mr-2">
              <span className="capitalize whitespace-nowrap">
                {dropdownValue}
              </span>

              {!dropdownValue && <span>Select One</span>}
            </span>

            {dropdownValue && <div className="w-3 lg:w-3.5" />}

            <img
              src="/chevron-down-gray.svg"
              alt="open"
              className="w-5 lg:w-6"
            />
          </button>

          {dropdownValue && (
            <img
              src="/cross-gray.svg"
              alt=""
              className="absolute top-1/2 -translate-y-1/2 right-[30px] lg:right-[38px] w-3 lg:w-3.5 cursor-pointer"
              onClick={() => {
                setDropdownValue("");
              }}
            />
          )}
        </div>

        <div
          onClick={() => setIsShowingDropdown(false)}
          className={`z-10 fixed inset-0 ${
            isShowingDropdown ? "pointer-events-auto" : "pointer-events-none"
          }`}
        ></div>

        <ul
          className={`absolute w-max bg-white border rounded-[8px] p-1 transition-all z-20 ${
            isShowingDropdown
              ? "opacity-100 translate-y-1"
              : "pointer-events-none opacity-0 translate-y-0"
          }`}
        >
          {values.map((val, index) => (
            <li
              key={index}
              onClick={() => {
                setIsShowingDropdown(false);
                setDropdownValue(val);
              }}
              className="capitalize hover:bg-[#F4F4F5] transition-colors cursor-pointer h-9 flex items-center px-2 text-[13px] lg:text-[15px] rounded-[7px] gap-2"
            >
              {val}
            </li>
          ))}
        </ul>
      </div>
    </td>
  );
};

export default DropdownFilter;
