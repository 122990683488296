import React, { useState } from "react";
import Button from "../../components/Button";
import { STEPS } from "./Main";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Input from "../../components/Input";

const InputPhoneStep = ({ setStep }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [buttonState, setButtonState] = useState("");

  const { token } = useParams();

  const handleChange = (e) => setPhoneNumber(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setButtonState("loading");

    try {
      const response = await axios.post(
        process.env.REACT_APP_EMAIL_SIGNUP_API_URL,
        {
          purpose: "update_phone_number",
          phone_number: phoneNumber,
          token,
        }
      );

      if (response.data.success) {
        toast.success(response.data.message);
        setStep(STEPS.INPUT_FULL_NAME);
      }
    } catch (err) {
      console.error(err);
    }

    setButtonState("");
  };

  return (
    <form onSubmit={handleSubmit} className="w-full flex flex-col gap-[18px]">
      <p className="px-4">Please enter your phone number below</p>

      <div className="flex flex-col gap-3 px-4">
        <Input
          variant="phone"
          name="phone_number"
          value={phoneNumber}
          onChange={handleChange}
        />
      </div>

      <div className="w-full h-[1px] bg-[#e5e7eb]"></div>

      <div className="w-full px-4 flex justify-end gap-2">
        <Button
          state={buttonState}
          type="submit"
          disabled={phoneNumber === "" || buttonState === "loading"}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export default InputPhoneStep;
