const downloadAsCsv = (data) => {
  const csvContent = data.map((row) => row.join(",")).join("\n");

  const blob = new Blob([csvContent], { type: "text/csv" });

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "data.csv";

  link.click();

  URL.revokeObjectURL(link.href);
};

export default downloadAsCsv;
