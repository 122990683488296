import React, { useEffect, useRef, useState } from "react";
import TableRow from "./TableRow";
import Filters from "../../components/table/Filters";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../../components/table/Pagination";
import TableHead from "./TableHead";
import axios from "axios";
import Loading from "../../components/Loading";
import ToggleColumns from "../../components/table/ToggleColumns";
import { toast } from "react-toastify";
import ConfirmDeleteAccount from "./ConfirmDeleteAccount";

const defaultColumns = [
  {
    key: "voter_id",
    text: "Voter ID",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "full_name",
    text: "Full Name",
    isActive: true,
    className: "capitalize",
    filter: { type: "search", value: "" },
  },
  {
    key: "status",
    text: "Status",
    isActive: false,
    className: "capitalize",
    filter: {
      type: "multiselect",
      value: "",
      values: [
        "active",
        "email_verification_sent",
        "approved-needs-to-set-password",
        "email_verified",
        "waiting",
        "input phone number",
        "not_registered_harris_county",
        "Empty",
      ],
    },
  },
  {
    key: "phone_number",
    text: "Phone Number",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "email",
    text: "Email",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "total_members",
    text: "Network Size",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "role",
    text: "Role",
    isActive: false,
    className: "capitalize",
    filter: {
      type: "dropdown",
      value: "",
      values: ["Ambassador", "Captain"],
    },
  },
  // {
  //   key: "ambassador_name",
  //   text: "Ambassador Name",
  //   isActive: false,
  //   filter: { type: "search", value: "" },
  // },
  {
    key: "primary_voting_history",
    text: "Primary Voting History",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  // {
  //   key: "ohps_rank",
  //   text: "OHPS Rank",
  //   isActive: false,
  //   filter: { type: "search", value: "" },
  // },
];

const defaultSort = { column: null, order: null };

const Table = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const pageInUrl = searchParams.get("page") ?? 1;
  const page = isNaN(+pageInUrl) ? 1 : +pageInUrl;

  const [filteredList, setFilteredList] = useState([]);

  const [sort, setSort] = useState(() => {
    const memory = JSON.parse(localStorage.getItem("admin-memory"));
    return memory?.sort || defaultSort;
  });
  const [columns, setColumns] = useState(() => {
    const memory = JSON.parse(localStorage.getItem("admin-memory"));
    return memory?.columns || defaultColumns;
  });

  const [perPage, setPerPage] = useState(() => {
    const memory = JSON.parse(localStorage.getItem("admin-memory"));
    return memory?.perPage || 10;
  });

  const [confirmationModal, setConfirmationModal] = useState({ type: null, email: null });

  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(null);

  const navigate = useNavigate();

  const [fetchingEditStatus, setFetchingEditStatus] = useState(false);
  const [fetchingEditRole, setFetchingEditRole] = useState(false);

  const [isFetchingData, setIsFetchingData] = useState(true);

  const apiSourceRef = useRef(null);

  const fetchParams = {
    per_page: perPage,
    sort,
    filters: getAppropriateFiltersFormat(),
  };

  const updateStatus = async (email, newStatus) => {
    if (!["denied", "active"].includes(newStatus)) return;

    setFetchingEditStatus(true);

    try {
      const response = await axios.post(
        process.env.REACT_APP_ADMIN_API_URL,
        {
          purpose: "change_status",
          email: email,
          new_status: newStatus,
          page,
          ...fetchParams,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt-token")}`,
          },
        }
      );

      setFilteredList(response.data.users);
      setTotalPages(response.data.total_pages);

      toast.success("Update successful");
    } catch (err) {
      toast.error("Something went wrong");
      console.log(err);
    }

    setFetchingEditStatus(false);
  };

  const updateRole = async (email, newRole) => {
    if (!["ambassador", "captain"].includes(newRole)) return;

    setFetchingEditRole(true);

    try {
      const response = await axios.post(
        process.env.REACT_APP_ADMIN_API_URL,
        {
          purpose: "change_role",
          email: email,
          new_role: newRole,
          page,
          ...fetchParams,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt-token")}`,
          },
        }
      );

      setFilteredList(response.data.users);
      setTotalPages(response.data.total_pages);
      setTotalUsers(response.data.total_users);

      toast.success("Update successful");
    } catch (err) {
      toast.error("Something went wrong");
      console.log(err);
    }

    setFetchingEditRole(false);
  };

  const fetchFilteredList = async (page) => {
    const controller = new AbortController();
    const { signal } = controller;
    apiSourceRef.current = controller;

    setIsFetchingData(true);

    const prevList = filteredList;
    setFilteredList([]);

    try {
      const response = await axios.post(
        process.env.REACT_APP_ADMIN_API_URL,
        {
          purpose: "load_volunteers",
          page,
          ...fetchParams,
        },
        {
          signal,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt-token")}`,
          },
        }
      );

      // localStorage.setItem("jwt-token", response.data.token);
      setFilteredList(response.data.users);
      setTotalPages(response.data.total_pages);
      setTotalUsers(response.data.total_users);

      setIsFetchingData(false);
    } catch (error) {
      if (
        error.response?.data === "Token has expired" ||
        error.response?.data === "Unauthorized" ||
        error.response?.data === "Invalid token"
      )
        navigate("/login");
      if (!error instanceof axios.CanceledError) {
        setFilteredList(prevList);
        console.error("There was an error submitting the data!", error);
        setIsFetchingData(false);
      }
    }
  };

  const firstRenderRef = useRef(true);

  // handles the initial fetch, filtering, sorting of the data
  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      fetchFilteredList(page);
    } else {
      setSearchParams({ page: 1 });
      fetchFilteredList(1);
    }

    return () => {
      if (apiSourceRef.current) apiSourceRef.current.abort();
    };
  }, [...columns.map((c) => c.filter.value), sort, perPage]);

  const firstRenderRef2 = useRef(true);

  useEffect(() => {
    if (firstRenderRef2.current) {
      firstRenderRef2.current = false;
    } else {
      localStorage.setItem(
        "admin-memory",
        JSON.stringify({ columns, sort, perPage })
      );
    }
  }, [
    ...columns.map((c) => c.isActive),
    ...columns.map((c) => c.filter.value),
    sort,
    perPage,
  ]);

  function getAppropriateFiltersFormat() {
    const filters = {};

    columns.forEach((col) => {
      if (col.key === "voted" && col.filter.value !== "")
        filters[col.key] = col.filter.value === "Voted" ? "1" : "0";
      else filters[col.key] = col.filter.value.toString().toLowerCase().trim();
    });

    return filters;
  }

  const [filtersKey, setFiltersKey] = useState(0);

  const resetColumns = () => {
    setColumns(
      columns.map((col) => ({
        ...col,
        filter: { ...col.filter, value: "" },
      }))
    );
  };

  const resetFilters = () => {
    localStorage.removeItem("admin-memory");

    resetColumns();
    setPerPage(10);
    setSort(defaultSort);

    setFiltersKey((prev) => (prev += 1));
  };

  return (
    <>
      {confirmationModal === "delete-account" && (
        <ConfirmDeleteAccount close={() => setConfirmationModal(null)} />
      )}

      <div className="flex flex-col w-full gap-3 sm:gap-4 pt-[24px] lg:pt-[30px]">
        <div className="flex flex-col xs:flex-row items-end xs:items-center justify-end gap-2 relative">
          <h1 className="text-2xl sm:text-3xl font-medium text-center absolute bottom-[calc(100%+12px)] lg:bottom-1/2 lg:translate-y-1/2 left-1/2 -translate-x-1/2 whitespace-nowrap">
            Total Users: {totalUsers ?? "Loading"}
          </h1>

          <button
            className="flex items-center font-semibold gap-1 md:gap-3 border h-11 px-2 md:px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px]"
            onClick={resetFilters}
          >
            Reset Filters
          </button>

          <ToggleColumns
            id="voter-id-toggle-columns-button"
            columns={columns}
            setColumns={setColumns}
            sort={sort}
            setSort={setSort}
          />
        </div>
        <div className="overflow-x-auto w-full rounded-md pb-[112px] sm:pb-[140px]">
          <table className="w-full [&_td]:px-2 [&_th]:px-2 border border-b-0">
            <TableHead
              columns={columns}
              setSort={setSort}
              isFetchingData={isFetchingData}
            />

            <tbody>
              <Filters
                key={filtersKey}
                columns={columns}
                setColumns={setColumns}
              />

              {filteredList.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  row={row}
                  columns={columns}
                  fetchingEditRole={fetchingEditRole}
                  fetchingEditStatus={fetchingEditStatus}
                  updateStatus={updateStatus}
                  updateRole={updateRole}
                  setConfirmationModal={setConfirmationModal}
                />
              ))}
            </tbody>
          </table>
        </div>
        {isFetchingData && (
          <div className="flex items-center justify-center my-6 -translate-y-[112px] sm:-translate-y-[140px]">
            <Loading />
          </div>
        )}
        <Pagination
          totalPages={totalPages}
          fetchFilteredList={fetchFilteredList}
          isFetchingData={isFetchingData}
          perPage={perPage}
          setPerPage={setPerPage}
          className="-translate-y-[112px] sm:-translate-y-[140px]"
        />
      </div>
    </>
  );
};

export default Table;
