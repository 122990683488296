import React, { useState } from "react";
import Button from "../Button";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../Loading";
import Input from "../Input";

const STEPS = {
  INPUT_NAME: "input-name",
  MATCH_USER: "match-user",
};

const MissingVoterId = ({ updateUserInfoAndSetNextStep, close }) => {
  const [step, setStep] = useState(STEPS.INPUT_NAME);
  const [fetchedVoters, setFetchedVoters] = useState(null);

  const back = () => setStep(STEPS.INPUT_NAME);

  if (step === STEPS.INPUT_NAME)
    return (
      <InputName
        setFetchedVoters={setFetchedVoters}
        setStep={setStep}
        close={close}
      />
    );

  if (step === STEPS.MATCH_USER)
    return (
      <MatchUser
        fetchedVoters={fetchedVoters}
        back={back}
        updateUserInfoAndSetNextStep={updateUserInfoAndSetNextStep}
      />
    );
};

export default MissingVoterId;

const InputName = ({ setFetchedVoters, setStep, close }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
  });

  const [buttonState, setButtonState] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonState("loading");

    if (
      formData.first_name === "" ||
      formData.last_name === "" ||
      buttonState === "loading"
    )
      return;

    try {
      const response = await axios.post(
        process.env.REACT_APP_CHECK_ACCOUNT_STANDARDS_API_URL,
        {
          purpose: "submit_name",
          ...formData,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt-token")}`,
          },
        }
      );

      if (response.data.success) {
        setFetchedVoters(response.data.matches);
        setStep(STEPS.MATCH_USER);
      } else toast.error(response.data.message);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong, please try again later");
    }

    setButtonState("");
  };

  return (
    <div className="max-w-[500px] w-[90%] bg-white rounded-lg">
      <div className="w-full border-b px-4 py-4 font-semibold text-2xl text-center">
        Voter ID
      </div>

      <form
        onSubmit={handleSubmit}
        className="w-full flex flex-col gap-[18px] leading-[1.5] py-6"
      >
        <p className="px-4">
          Please enter your full legal name in the box below
        </p>

        <div className="flex flex-col gap-3 px-4">
          <Input
            type="text"
            name="first_name"
            placeholder="First Name"
            value={formData.first_name}
            onChange={handleChange}
          />
          <Input
            type="text"
            name="middle_name"
            placeholder="Middle Name"
            value={formData.middle_name}
            onChange={handleChange}
          />
          <Input
            type="text"
            name="last_name"
            placeholder="Last Name"
            value={formData.last_name}
            onChange={handleChange}
          />
        </div>

        <div className="w-full h-[1px] bg-[#e5e7eb]"></div>

        <div className="w-full px-4 flex justify-end gap-2">
          <button
            type="button"
            disabled={buttonState === "loading"}
            onClick={close}
            className="flex items-center h-9 rounded-md bg-[#e3e4e9] text-[#45484e] px-[22px] font-semibold disabled:opacity-70 disabled:cursor-not-allowed"
          >
            Skip
          </button>

          <Button
            state={buttonState}
            type="submit"
            disabled={
              formData.first_name === "" ||
              formData.last_name === "" ||
              buttonState === "loading"
            }
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

const MatchUser = ({ fetchedVoters, back, updateUserInfoAndSetNextStep }) => {
  const [buttonState, setButtonState] = useState(null);

  const handleSelect = async (voter) => {
    setButtonState("loading");

    try {
      const response = await axios.post(
        process.env.REACT_APP_CHECK_ACCOUNT_STANDARDS_API_URL,
        {
          purpose: "set_voter_id",
          voter_id: voter.voter_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt-token")}`,
          },
        }
      );

      toast.success(response.data.message);
      updateUserInfoAndSetNextStep({ voter_id: voter.voter_id });
    } catch (err) {
      toast.error("Something went wrong");
    }

    setButtonState(null);
  };

  return (
    <div className="max-w-[500px] w-[90%] bg-white rounded-lg">
      <div className="w-full border-b px-4 py-4 font-semibold text-2xl text-center">
        Voter ID
      </div>

      <div className="w-full flex flex-col gap-[18px] leading-[1.5] py-6">
        <p className="px-4">
          Please click the select button on the row that matches your registered
          Voter ID.
        </p>

        <table className="px-5 text-start">
          <thead>
            <tr className="border-b border-[#ccc]">
              <th className="text-start pl-6 px-2 pb-3">Voter ID</th>
              <th className="text-start px-2 pb-3">Full Name</th>
              <th className="text-start px-2 pb-3">Full Address</th>
              <th className="text-start pr-4 px-2 pb-3"></th>
            </tr>
          </thead>

          <tbody>
            {fetchedVoters?.map((voter, i) => (
              <tr
                key={i}
                className="border-b border-[#ccc] [&>td]:px-2 [&>td]:py-3"
              >
                <td className="!pl-6">{voter.voter_id}</td>
                <td className="capitalize pl-">
                  {voter.first_name} {voter.middle_name} {voter.last_name}
                </td>
                <td className="capitalize">{voter.full_address}</td>
                <td
                  onClick={() => handleSelect(voter)}
                  className="pr-4 font-bold cursor-pointer text-cherry text-center relative"
                >
                  <span
                    className={
                      buttonState === "loading" ? "opacity-0" : "opacity-100"
                    }
                  >
                    Submit
                  </span>

                  {buttonState === "loading" && (
                    <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                      <Loading size={18} />
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="w-full px-4 flex gap-2">
          <button
            onClick={back}
            className="flex items-center h-9 rounded-md bg-[#e3e4e9] text-[#45484e] px-[22px] font-semibold"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};
