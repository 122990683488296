import React, { useEffect, useRef, useState } from "react";

const Dropdown = ({
  className = "",
  dropdownClassName = "",
  button,
  listItems,
  onClose = () => {},
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const listWrapper = useRef();

  const closeDropdown = () => setIsExpanded(false);

  useEffect(() => {
    const listItems = listWrapper.current.querySelectorAll("li");

    listItems.forEach((item) => {
      if (item.getAttribute("aria-label") === "list-option") {
        item.addEventListener("click", closeDropdown);
      }
    });

    return () =>
      listItems.forEach((item) => {
        if (item.getAttribute("aria-label") === "list-option") {
          item.removeEventListener("click", closeDropdown);
        }
      });
  }, [listItems]);

  useEffect(() => {
    if (isExpanded === false) onClose();
  }, [isExpanded]);

  return (
    <div className={`${className}`}>
      <button
        className={`relative ${isExpanded ? "z-20" : "z-0"}`}
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        {button}
      </button>

      <div
        onClick={() => setIsExpanded(false)}
        className={`z-10 fixed inset-0 ${
          isExpanded ? "pointer-events-auto" : "pointer-events-none"
        }`}
      ></div>

      <ul
        ref={listWrapper}
        className={`z-30 absolute w-max bg-white border rounded-[8px] top-full right-0 p-1 transition-all ${dropdownClassName} ${
          isExpanded
            ? "opacity-100 translate-y-1"
            : "pointer-events-none opacity-0 translate-y-0"
        }`}
      >
        {listItems}
      </ul>
    </div>
  );
};

export default Dropdown;
