import React from "react";
import Button from "../Button";
import { MODALS } from ".";

const StartModal = ({ userInfo, close, setActiveModal }) => {
  const start = () => {
    if (!userInfo.verified_email) {
      setActiveModal(MODALS.VERIFY_EMAIL);
    } else if (!userInfo.phone_number) {
      setActiveModal(MODALS.PHONE_NUMBER);
    } else if (!userInfo.voter_id) {
      setActiveModal(MODALS.VOTER_ID);
    }
  };

  const missingInfo = [];
  if (!userInfo.verified_email) missingInfo.push("Verified Email");
  if (!userInfo.phone_number) missingInfo.push("Phone Number");
  if (!userInfo.voter_id) missingInfo.push("Voter ID");

  return (
    <div className="max-w-[500px] w-[90%] bg-white rounded-lg">
      <div className="w-full border-b px-4 py-4 font-semibold text-2xl text-center">
        Missing Info
      </div>

      <div className="flex flex-col px-5 py-4 gap-4">
        <p className="font-medium">
          Your account is missing some important details:
        </p>

        <ul className="font-medium">
          {missingInfo.map((info, index) => (
            <li key={index}>{info}</li>
          ))}
        </ul>
        <p>
          Please complete these in the next steps. This helps us keep your
          account secure and you informed.
        </p>
      </div>

      <div className="w-full h-[1px] bg-[#e5e7eb]"></div>

      <div className="w-full p-4 flex justify-end gap-2">
        <button
          onClick={close}
          className="flex items-center h-9 rounded-md bg-[#e3e4e9] text-[#45484e] px-[22px] font-semibold"
        >
          Close
        </button>

        <Button onClick={start}>Start</Button>
      </div>
    </div>
  );
};

export default StartModal;