import React from "react";

const OtpInput = ({ formId }) => {
  const handleKeyDown = (e) => {
    const form = document.getElementById(formId);
    const inputs = [...form.querySelectorAll("input[type=text]")];

    if (
      !/^[0-9]{1}$/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "Tab" &&
      !e.metaKey
    ) {
      e.preventDefault();
    }

    if (e.key === "Delete" || e.key === "Backspace") {
      const index = inputs.indexOf(e.target);

      if (e.target.value) {
        e.target.value = "";
      } else if (index > 0) {
        inputs[index - 1].value = "";
        inputs[index - 1].focus();
      }
    }
  };

  const handleInput = (e) => {
    const form = document.getElementById(formId);
    const inputs = [...form.querySelectorAll("input[type=text]")];

    const { target } = e;
    const index = inputs.indexOf(target);
    if (target.value) {
      if (index < inputs.length - 1) inputs[index + 1].focus();
    }
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handlePaste = (e) => {
    const form = document.getElementById(formId);
    const inputs = [...form.querySelectorAll("input[type=text]")];

    e.preventDefault();
    const text = e.clipboardData.getData("text");
    if (!new RegExp(`^[0-9]{${inputs.length}}$`).test(text)) {
      return;
    }
    const digits = text.split("");
    inputs.forEach((input, index) => (input.value = digits[index]));
  };

  return (
    <div className="flex items-center justify-center gap-3">
      <input
        type="text"
        className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-cherry focus:ring-2 focus:ring-indigo-100"
        pattern="\d*"
        name="first_digit"
        maxLength="1"
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onPaste={handlePaste}
      />
      <input
        type="text"
        className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-cherry focus:ring-2 focus:ring-indigo-100"
        name="second_digit"
        maxLength="1"
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onPaste={handlePaste}
      />
      <input
        type="text"
        className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-cherry focus:ring-2 focus:ring-indigo-100"
        name="third_digit"
        maxLength="1"
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onPaste={handlePaste}
      />
      <input
        type="text"
        className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-cherry focus:ring-2 focus:ring-indigo-100"
        name="fourth_digit"
        maxLength="1"
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onPaste={handlePaste}
      />
    </div>
  );
};

export default OtpInput;
