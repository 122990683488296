import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import axios from "axios";
import Input from "../../components/Input";

const ManualSearch = ({ setModalType, setFetchedVoters }) => {
  const [buttonState, setButtonState] = useState(null);
  const [showAddUserButton, setShowAddUserButton] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const clearForm = () => {
    setFormData({
      first_name: "",
      middle_name: "",
      last_name: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonState("loading");
    setShowAddUserButton(false);

    if (
      formData.first_name === "" ||
      formData.last_name === "" ||
      buttonState === "loading"
    )
      return;

    try {
      const response = await axios.post(
        process.env.REACT_APP_EMAIL_SIGNUP_API_URL,
        {
          purpose: "submit_name",
          ...formData,
        }
      );

      if (response.data.success) {
        setFetchedVoters(response.data.matches);
        setModalType("results");
        clearForm();
      } else {
        if (
          response.data.message ===
          "No matching voter records found. Please try adjusting your search parameters. If you still can't find your record, click the 'Can't Find Voter ID' button to submit your information for assistance."
        ) {
          toast.info(
            "No matching records found. You can add this user without a VUID, but their voting status won't be tracked until a Voter ID is added later."
          );
          setShowAddUserButton(true);
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong, please try again later");
    }

    setButtonState(null);
  };

  const handleAddUser = async () => {
    setButtonState("loading");

    const lowerFormData = Object.keys(formData).reduce((acc, curr) => {
      return { [curr]: formData[curr].toLowerCase(), ...acc };
    }, {});

    try {
      const response = await axios.post(
        process.env.REACT_APP_ADD_NEW_ROW_API_URL,
        {
          voter_id: "missing",
          purpose: "manual_data_add",
          page: 1,
          per_page: 10,
          sort: { column: null, order: null },
          filters: {},
          ...lowerFormData,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt-token")}`,
          },
        }
      );
      console.log("Add New Row Response:", response);

      if (response.status === 200 && response.data.message) {
        localStorage.setItem("jwt-token", response.data.token);
        toast.success(response.data.message);
        clearForm();
        setShowAddUserButton(false);
      } else {
        toast.error("Unexpected response from server");
      }
    } catch (err) {
      console.error("Add New Row Error:", err);
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Failed to add user to list");
      }
    }
    setButtonState(null);
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 xs:p-5 flex flex-col gap-4">
      <div className="space-y-0.5">
        <h6 className="text-[#323232] font-semibold text-lg">Instructions</h6>
        <p className="text-[#969696] leading-[1.35] text-lg">
          Enter the first, middle, and last name manually to search for matching
          records in the database.
        </p>
      </div>

      <div className="flex flex-col gap-2.5 text-lg">
        <Input
          type="text"
          name="first_name"
          placeholder="First Name"
          value={formData.first_name}
          onChange={handleChange}
        />

        <Input
          type="text"
          name="middle_name"
          placeholder="Middle Name"
          value={formData.middle_name}
          onChange={handleChange}
        />

        <Input
          type="text"
          name="last_name"
          placeholder="Last Name"
          value={formData.last_name}
          onChange={handleChange}
        />
      </div>

      <div className="flex gap-2 justify-end">
        {showAddUserButton && (
          <Button
            type="button"
            onClick={handleAddUser}
            state={buttonState}
            className="w-full h-[48px] text-lg !rounded-lg bg-blue-500 hover:bg-blue-600 flex-grow"
          >
            Add Without VUID
          </Button>
        )}

        <Button
          type="submit"
          state={buttonState}
          disabled={
            formData.first_name === "" ||
            formData.last_name === "" ||
            buttonState === "loading"
          }
          className={`w-full h-[48px] text-lg !rounded-lg ${
            showAddUserButton ? "w-1/2" : "w-full"
          }`}
        >
          Search
        </Button>
      </div>
    </form>
  );
};

export default ManualSearch;
