import React from "react";
import { Link } from "react-router-dom";

const WaitingScreen = () => {
  return (
    <>
      <div className="px-5 space-y-2.5">
        <p>
          Thank you for your patience. Your account is under review to ensure it
          meets our standards.
        </p>
        <p>We will notify you via email once we review.</p>

        <p>For questions, email us at support@fireduptovote.com.</p>
      </div>

      <div className="w-full h-[1px] bg-[#e5e7eb]"></div>

      <div className="w-full px-4 flex justify-end gap-2">
        <Link
          to="/"
          className="h-9 rounded-md bg-cherry text-white hover:bg-white hover:text-cherry transition-all duration-200 border border-cherry px-[22px] font-semibold relative disabled:cursor-not-allowed disabled:bg-cherry disabled:text-white disabled:opacity-70 flex items-center"
        >
          Home
        </Link>
      </div>
    </>
  );
};

export default WaitingScreen;
