import React, { useState } from "react";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

const InCompleteRowSearchResult = ({
  setFilteredList,
  setTotalPages,
  close,
  fetchedVoters,
  back,
  filters,
  currentPage,
  perPage,
  currentSort
}) => {
  const [_, setSearchParams] = useSearchParams();

  const [loadingBtnIndex, setLoadingBtnIndex] = useState(null);

  const onAddVoter = async (voter, index) => {
    setLoadingBtnIndex(index);
    setTimeout(() => {
      setLoadingBtnIndex(null);
    }, 5000);

    try {
      console.log("filters:", filters);
      const response = await axios.post(
        process.env.REACT_APP_ADD_NEW_ROW_API_URL,
        {
          voter_id: String(voter.voter_id),
          purpose: "manual_data_add",
          page: currentPage,
          per_page: perPage,
          sort: currentSort,
          filters: filters,
          search_first_name: localStorage.getItem('search_first_name'),
          search_last_name: localStorage.getItem('search_last_name')
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt-token")}`,
          },
        }
      );
      console.log(response);

      localStorage.setItem("jwt-token", response.data.token);
      setFilteredList(response.data.items);
      setTotalPages(response.data.total_pages);
      setSearchParams({ page: currentPage.toString() });
      toast.success(`Voter added to list`);
      close();
    } catch (err) {
      toast.error("Failed to add voter to list");
    }

    setLoadingBtnIndex(null);
  };

  return (
    <div className="w-[90%] overflow-hidden max-h-[90vh] max-w-[780px] bg-white flex flex-col rounded-xl relative">
      <div className="bg-[#FAFAFA] rounded-t-xl px-4 xs:px-5 pt-5 space-y-2.5 pb-1">
        <div className="flex justify-between items-start gap-6">
          <div className="flex gap-4">
            {back && (
              <button onClick={back} className="w-4 flex justify-center">
                <img
                  src="/chevron-down.svg"
                  alt=""
                  className="rotate-90 min-w-[200%]"
                />
              </button>
            )}

            <div className="space-y-0.5">
              <h6 className="text-xl xs:text-2xl text-[#323232] font-semibold font-mont !leading-[1.3]">
                Results
              </h6>
              <p className="text-[#727272]">
                Select the voter you want to add to your list
              </p>
            </div>
          </div>

          <button
            onClick={close}
            className="bg-white border-black border size-8 min-w-8 rounded-lg flex justify-center items-center"
          >
            <img src="/cross.svg" className="size-4" />
          </button>
        </div>
      </div>

      <div className="w-full h-full overflow-y-auto red-scrollbar overscroll-contain">
        <table className="w-full">
          <thead className="bg-[#FAFAFA] border-b text-[#969696] sticky top-0 z-10">
            <tr>
              <th className="py-4 text-sm sm:text-base px-3.5 sm:px-5 font-semibold text-start whitespace-nowrap">
                <div className="flex gap-1 sm:gap-2.5 items-center">
                  <img src="/id.svg" />
                  <span>Voter ID</span>
                </div>
              </th>
              <th className="py-4 text-sm sm:text-base px-3.5 sm:px-5 font-semibold text-start whitespace-nowrap">
                <div className="flex gap-1 sm:gap-2.5 items-center">
                  <img src="/school-report-card.svg" />
                  <span>Full Name</span>
                </div>
              </th>
              <th className="py-4 text-sm sm:text-base px-3.5 sm:px-5 font-semibold text-start whitespace-nowrap">
                <div className="flex gap-1 sm:gap-2.5 items-center">
                  <img src="/address-book.svg" />
                  <span>Home Address</span>
                </div>
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody className="text-[#727272]">
            {fetchedVoters.map((voter, index) => (
              <tr key={index} className="border-b">
                <td className="py-2.5 text-sm sm:text-base px-3.5 sm:px-5 font-medium border-r">
                  {voter.voter_id}
                </td>
                <td className="py-2.5 text-sm sm:text-base px-3.5 sm:px-5 font-medium border-r capitalize">
                  {voter.first_name} {voter.middle_name} {voter.last_name}
                </td>
                <td className="py-2.5 text-sm sm:text-base px-3.5 sm:px-5 font-medium border-r capitalize">
                  {voter.full_address}
                </td>
                <td className="px-3.5 sm:px-5 text-sm sm:text-base font-semibold text-cherry text-center">
                  <button
                    disabled={loadingBtnIndex !== null}
                    onClick={() => onAddVoter(voter, index)}
                    className="relative w-full"
                  >
                    <span
                      className={
                        loadingBtnIndex === index
                          ? "opacity-0"
                          : loadingBtnIndex !== null
                          ? "opacity-70"
                          : '"opacity-100"'
                      }
                    >
                      Select
                    </span>

                    {loadingBtnIndex === index && (
                      <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                        <Loading size={18} />
                      </div>
                    )}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InCompleteRowSearchResult;