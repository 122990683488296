import React from "react";

const Hero = () => {
  return (
    <div id="hero" className="flex justify-center">
      <div className="w-[90%] max-w-[1200px] flex flex-col items-center gap-[40px] sm:gap-[70px] mt-[120px] sm:mt-[130px]">
        <img
          width={489}
          height={282}
          src="/hero-img.png"
          alt="fired up to vote"
          className="max-w-[482px] min-w-[250px] w-[60%]"
        />

        <p className="relative text-[#9FA3A4] text-[30px] sm:text-[38px] md:text-[44px] lg:text-[56px] max-w-[15.17em] text-center font-fira font-extrabold sm:font-black leading-[1.15] tracking-[0.5px]">
          "THE PRICE GOOD MEN PAY FOR INDIFFERENCE TO PUBLIC AFFAIRS IS TO BE
          RULED BY EVIL MEN."
          <span className="text-black text-[18px] sm:text-[22px] md:text-[26px] lg:text-3xl font-medium absolute bottom-0 right-[5%] translate-y-[95%] sm:translate-y-[75%]">
            -PLATO
          </span>
        </p>

        <img
          src="/crowd-holding-vote-signs.png"
          alt="crowd holding vote signs"
          className="w-full"
          width={1081}
          height={347}
        />
      </div>
    </div>
  );
};

export default Hero;
