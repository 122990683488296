import React, { useState } from "react";
import Button from "../Button";
import axios from "axios";
import { toast } from "react-toastify";
import Input from "../Input";

const MissingPhoneNumber = ({
  updateUserInfoAndSetNextStep,
  userInfo,
  close,
}) => {
  const [buttonState, setButtonState] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (e) => setPhoneNumber(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setButtonState("loading");

    try {
      const response = await axios.post(
        process.env.REACT_APP_CHECK_ACCOUNT_STANDARDS_API_URL,
        { phone_number: phoneNumber, purpose: "phone_number_input" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt-token")}`,
          },
        }
      );

      toast.success(response.data.message);
      updateUserInfoAndSetNextStep({ phone_number: phoneNumber });
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
    }

    setButtonState("");
  };

  return (
    <div className="max-w-[500px] w-[90%] bg-white rounded-lg">
      <div className="w-full border-b px-4 py-4 font-semibold text-2xl text-center">
        Phone Number
      </div>

      <form
        onSubmit={handleSubmit}
        className="w-full flex flex-col leading-[1.5] pt-6"
      >
        <p className="px-4">Please enter your phone number below</p>

        <div className="flex flex-col gap-3 mt-3 px-4">
          <Input
            variant="phone"
            name="phone_number"
            value={phoneNumber}
            onChange={handleChange}
          />
        </div>

        <div className="w-full h-[1px] bg-[#e5e7eb] mt-6"></div>

        <div className="w-full p-4 flex justify-end gap-2">
          <button
            disabled={buttonState === "loading"}
            onClick={close}
            className="flex items-center h-9 rounded-md bg-[#e3e4e9] text-[#45484e] px-[22px] font-semibold disabled:opacity-70 disabled:cursor-not-allowed"
          >
            Skip
          </button>

          <Button
            state={buttonState}
            type="submit"
            disabled={phoneNumber === "" || buttonState === "loading"}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default MissingPhoneNumber;
