export const defaultColumns = [
  {
    key: "voter_id",
    text: "Voter ID",
    isActive: false,
    filter: { type: "search", value: "" },
  },
  {
    key: "first_name",
    text: "First Name",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "last_name",
    text: "Last Name",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "voted",
    text: "Voted",
    isActive: true,
    filter: { type: "dropdown", value: "", values: ["Voted", "Not Voted"] },
  },
];

export const defaultSort = { column: null, order: null };

export const tempData = [
  {
    first_name: "John",
    last_name: "Doe",
    voted: "0",
    voter_id: "9999999999",
  },
];
