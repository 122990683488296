import React, { useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import Ascending from "../../svg/Ascending";
import Descending from "../../svg/Descending";

const TableHead = ({ columns, setSort, isFetchingData }) => {
  const [instantSort, setInstantSort] = useState({ column: null, order: null });

  const updateSort = (column) => {
    if (isFetchingData) return;

    let newSort = {};

    if (instantSort.column !== column)
      newSort = { column: column, order: "desc" };
    else if (instantSort.order === "asc")
      newSort = { ...instantSort, order: "desc" };
    else newSort = { ...instantSort, order: "asc" };

    setInstantSort(newSort);
  };

  useDebounce(
    () => {
      setSort(instantSort);
    },
    [instantSort],
    500,
    true
  );

  return (
    <thead>
      <tr className="border-b h-[52px] rounded-t-lg">
        {columns
          .filter((col) => col.isActive)
          .map((col, index) => (
            <th
              key={col.key}
              onClick={() => updateSort(col.key)}
              className={`text-start font-semibold transition-all cursor-pointer select-none text-sm lg:text-base first:pl-4  ${
                isFetchingData
                  ? "bg-[#e5e7eb] opacity-60 hover:bg-[#e5e7eb] cursor-default"
                  : instantSort.column === col.key
                  ? "hover:bg-[#ecfeff] text-[#0e7490]"
                  : "hover:bg-[#F9F9FA]"
              }`}
            >
              <div className="flex gap-[5px] sm:gap-2 h-10 items-center rounded-md w-max">
                <span>{col.text}</span>
                <div
                  id={index === 1 ? "data-table-sort-first" : ""}
                  className="flex justify-center w-[17px] lg:w-5"
                >
                  {instantSort.column !== col.key && (
                    <img
                      src="/flip.svg"
                      alt="flip"
                      className="w-[17px] lg:w-5"
                    />
                  )}
                  {instantSort.column === col.key &&
                    (instantSort.order === "asc" ? (
                      <Ascending className="w-[15px] lg:w-[18px]" />
                    ) : (
                      <Descending className="w-[15px] lg:w-[18px]" />
                    ))}
                </div>
              </div>
            </th>
          ))}

        <th className="text-center min-w-[44px]">
          {/* hidden */}
          <button className="w-[36px] border p-1 rounded-lg hover:bg-[#F9F9FA] transition-colors pointer-events-none opacity-0 invisible">
            <img src="/plus.svg" alt="add" className="w-full" />
          </button>
        </th>
      </tr>
    </thead>
  );
};

export default TableHead;
