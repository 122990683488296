import React from "react";
import { Steps } from "intro.js-react";

const VoterIdLookupSteps = ({
  isShowing,
  setIsShowing,
  setFilteredList,
  addedTempData,
}) => {
  return (
    <Steps
      enabled={isShowing}
      steps={[
        {
          element: "#voter-id-lookup-table .search-btn",
          title: "Search for Voters",
          intro:
            "Click here to search for voters using the information you've entered in the fields above.",
        },
        {
          element: "#voter-id-toggle-columns-button",
          title: "Customize Your View",
          intro:
            "Click here to choose which information columns you want to see in your search results.",
        },
        {
          element: "#data-table-sort-first",
          title: "Organize Results",
          intro:
            "Click on any column header to sort your search results based on that information. Click again to reverse the order.",
        },
        {
          element: "#voter-id-lookup-table .filter",
          title: "Filter Results",
          intro:
            "Type here to further narrow down your search results. This helps you quickly find specific voters.",
        },
        {
          element: "#voter-id-lookup-table .filter-last_name",
          title: "Last Name Search",
          intro:
            "Including a last name in your search will make it much faster and more accurate.",
        },
        {
          element: ".voter-id-lookup-add-to-list",
          title: "Add Voter to List",
          intro:
            "Click 'Add Voter' next to a search result, then select which of your lists you want to add this voter to.",
        },
      ]}
      initialStep={0}
      onExit={() => {
        setIsShowing(false);
        if (addedTempData.current) setFilteredList([]);
        addedTempData.current = false;
      }}
      options={{ doneLabel: "Done" }}
    />
  );
};

export default VoterIdLookupSteps;
