import React, { useEffect, useRef } from "react";
import DropdownFilter from "./DropdownFilter";
import SearchFilter from "./SearchFilter";
import Button from "../Button";
import MultiSelectFilter from "./MultiSelectFilter";

const Filters = ({
  columns,
  setColumns,
  filteredList = undefined,
  setSelectedRows = undefined,
  allRowsSelected = undefined,
  selectAllRows = false,
  submitBtn = false,
  isFetchingData = undefined,
  fetchFilteredList = undefined,
  searchDependencies = [],
}) => {
  if (submitBtn) {
    if (isFetchingData === undefined)
      throw new Error("isFetchingData is undefined");
    if (fetchFilteredList === undefined)
      throw new Error("fetchFilteredList is undefined");
  }

  if (selectAllRows) {
    if (filteredList === undefined)
      throw new Error("filteredList is undefined");
    if (setSelectedRows === undefined)
      throw new Error("setSelectedRows is undefined");
    if (allRowsSelected === undefined)
      throw new Error("allRowsSelected is undefined");
  }

  const rowRef = useRef();

  useEffect(() => {
    if (submitBtn) {
      const handleKeyPress = (e) => {
        if (e.key === "Enter" && !isFetchingData) fetchFilteredList(1);
      };

      window.addEventListener("keypress", handleKeyPress);
      return () => window.removeEventListener("keypress", handleKeyPress);
    }
  }, [...searchDependencies, isFetchingData]);

  const toggleAllRows = () => {
    if (!selectAllRows) return;

    if (allRowsSelected) {
      setSelectedRows((prev) => {
        return prev.filter((prevId) => {
          return !filteredList.find((row) => row.id === prevId);
        });
      });
    } else {
      const newSelectedRows = filteredList.map((row) => row.id);
      setSelectedRows(newSelectedRows);
    }
  };

  const updateFilters = (key, newFilterValue) => {
    setColumns((columns) => {
      return columns.map((col) => {
        if (col.key !== key) return col;
        return { ...col, filter: { ...col.filter, value: newFilterValue } };
      });
    });
  };

  return (
    <tr ref={rowRef} className="border-b">
      {selectAllRows && (
        <td>
          <div className="flex justify-center text-sm lg:text-base filter-select-all-rows">
            <input
              type="checkbox"
              className="checkbox checkbox-black"
              checked={allRowsSelected}
              onChange={toggleAllRows}
            />
          </div>
        </td>
      )}

      {columns
        .filter((col) => col.isActive)
        .map((col, index) => {
          switch (col.filter.type) {
            case "search":
              return (
                <SearchFilter
                  key={col.key}
                  updateFilters={updateFilters}
                  colKey={col.key}
                  {...col.filter}
                  index={index}
                  debounce={!submitBtn}
                />
              );
            case "dropdown":
              return (
                <DropdownFilter
                  key={col.key}
                  updateFilters={updateFilters}
                  colKey={col.key}
                  {...col.filter}
                  index={index}
                  debounce={!submitBtn}
                />
              );
            case "multiselect":
              return (
                <MultiSelectFilter
                  key={col.key}
                  updateFilters={updateFilters}
                  colKey={col.key}
                  {...col.filter}
                  debounce={!submitBtn}
                />
              );
          }
        })}

      {submitBtn && (
        <td className="h-[52px] py-1.5 min-w-[107px] sticky right-0 bg-white">
          <Button
            state={isFetchingData ? "loading" : ""}
            disabled={isFetchingData}
            onClick={() => fetchFilteredList(1)}
            className="search-btn text-[13px] lg:text-[15px] !px-1 w-full h-full"
          >
            Search
          </Button>
        </td>
      )}
    </tr>
  );
};

export default Filters;
