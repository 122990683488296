import React, { useRef } from "react";
import Loading from "./Loading";
import { Link } from "react-router-dom";

const Button = ({ children, state = "", className = "", to, ...props }) => {
  const btnRef = useRef();

  const renderButtonChildren = () => {
    return (
      <>
        <span className={state === "loading" ? "opacity-0" : "opacity-100"}>
          {children}
        </span>

        {state === "loading" && (
          <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
            <Loading
              invert
              size={btnRef.current.getBoundingClientRect().height / 2}
            />
          </div>
        )}
      </>
    );
  };

  if (to) {
    return (
      <Link
        to={to}
        ref={btnRef}
        className={`flex justify-center items-center h-9 rounded-md bg-cherry text-white hover:bg-white hover:text-cherry transition-colors duration-200 border border-cherry px-[22px] font-semibold relative disabled:cursor-not-allowed disabled:bg-cherry disabled:text-white disabled:opacity-70 ${className}`}
        {...props}
      >
        {renderButtonChildren()}
      </Link>
    );
  }

  return (
    <button
      ref={btnRef}
      disabled={
        props.disabled !== undefined ? props.disabled : state === "loading"
      }
      className={`h-9 rounded-md bg-cherry text-white hover:bg-white hover:text-cherry transition-all duration-200 border border-cherry px-[22px] font-semibold relative disabled:cursor-not-allowed disabled:bg-cherry disabled:text-white disabled:opacity-70 ${className}`}
      {...props}
    >
      {renderButtonChildren()}
    </button>
  );
};

export default Button;
