import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";

const TableRow = ({
  row,
  columns,
  fetchingEditRole,
  fetchingEditStatus,
  index,
  updateStatus,
  updateRole,
  setConfirmationModal,
}) => {
  const [dropdownIsExpanded, setDropdownIsExpanded] = useState(false);

  useEffect(() => {
    if (!fetchingEditStatus) setDropdownIsExpanded(false);
  }, [fetchingEditStatus]);

  useEffect(() => {
    if (!fetchingEditRole) setDropdownIsExpanded(false);
  }, [fetchingEditRole]);

  return (
    <>
      <tr className="border-b h-[52px] transition-colors relative">
        {columns
          .filter((col) => col.isActive)
          .map((col, dataIndex) => (
            <td
              key={`${dataIndex}`}
              className={`text-start text-sm lg:text-base first:pl-4 whitespace-nowrap ${
                col.className ? col.className : ""
              }`}
            >
              {row[col.key]}
            </td>
          ))}

        <td
          className={`text-center h-[52px] sticky right-0 !p-0 bg-white border-r border-b ${
            dropdownIsExpanded ? "z-20" : "z-0"
          }`}
        >
          <div
            className={`text-sm lg:text-[15px] flex items-center justify-center relative`}
          >
            <button
              id={index === 0 ? "voter-id-add-to-list" : ""}
              onClick={() => setDropdownIsExpanded((prev) => !prev)}
            >
              <div className="rounded-lg hover:bg-[#F9F9FA] transition-colors font-semibold">
                Edit
              </div>
            </button>

            <div
              onClick={() => setDropdownIsExpanded(false)}
              className={`fixed inset-0 ${
                dropdownIsExpanded
                  ? "pointer-events-auto"
                  : "pointer-events-none"
              }`}
            ></div>

            <div
              className={`absolute w-max bg-white border rounded-[8px] transition-all top-full right-[50%] ${
                dropdownIsExpanded
                  ? "opacity-100 translate-y-1"
                  : "pointer-events-none opacity-0 translate-y-0"
              }`}
            >
              <div className="hover:bg-[#F9F9FA] transition-colors cursor-pointer h-8 lg:h-9 flex items-center px-2 rounded-[7px] gap-2 text-black font-medium relative group">
                Edit Status
                <div className="absolute right-full top-0 bg-white border rounded-[8px] -translate-x-1 group-hover:opacity-100 opacity-0 group-hover:pointer-events-auto pointer-events-none before:absolute before:h-full before:w-1 before:left-full">
                  <div
                    className={`absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2
                  ${
                    !fetchingEditStatus
                      ? "opacity-0 pointer-events-none invisible"
                      : ""
                  }
                    `}
                  >
                    <Loading size={24} />
                  </div>

                  <div
                    className={
                      fetchingEditStatus
                        ? "opacity-0 pointer-events-none invisible"
                        : ""
                    }
                  >
                    <div
                      onClick={() => updateStatus(row.email, "active")}
                      aria-label="list-option"
                      className="hover:bg-[#F9F9FA] transition-colors cursor-pointer h-8 lg:h-9 flex items-center px-2 rounded-[7px] gap-2 text-green-600 font-medium"
                    >
                      Approve
                    </div>
                    <div
                      onClick={() => updateStatus(row.email, "denied")}
                      aria-label="list-option"
                      className="hover:bg-[#F9F9FA] transition-colors cursor-pointer h-8 lg:h-9 flex items-center px-2 rounded-[7px] gap-2 text-cherry font-medium"
                    >
                      Deny
                    </div>
                  </div>
                </div>
              </div>

              <div className="hover:bg-[#F9F9FA] transition-colors cursor-pointer h-8 lg:h-9 flex items-center px-2 rounded-[7px] gap-2 text-black font-medium relative group">
                Modify Account
              </div>

              <div
                onClick={() => setConfirmationModal("delete-account")}
                className="hover:bg-[#F9F9FA] transition-colors cursor-pointer h-8 lg:h-9 flex items-center px-2 rounded-[7px] gap-2 text-cherry font-medium relative group"
              >
                Delete Account
              </div>

              {/* <div className="hover:bg-[#F9F9FA] transition-colors cursor-pointer h-8 lg:h-9 flex items-center px-2 rounded-[7px] gap-2 text-black font-medium relative group">
                Edit Role
                <div className="absolute right-full top-0 bg-white border rounded-[8px] -translate-x-1 group-hover:opacity-100 opacity-0 group-hover:pointer-events-auto pointer-events-none before:absolute before:h-full before:w-1 before:left-full">
                  <div
                    className={`absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2
                  ${
                    !fetchingEditRole
                      ? "opacity-0 pointer-events-none invisible"
                      : ""
                  }
                    `}
                  >
                    <Loading size={24} />
                  </div>

                  <div
                    className={
                      fetchingEditRole
                        ? "opacity-0 pointer-events-none invisible"
                        : ""
                    }
                  >
                    <div
                      onClick={() => updateRole(row.email, "ambassador")}
                      aria-label="list-option"
                      className="hover:bg-[#F9F9FA] transition-colors cursor-pointer h-8 lg:h-9 flex items-center px-2 rounded-[7px] gap-2 text-black font-medium"
                    >
                      Ambassador
                    </div>
                    <div
                      onClick={() => updateRole(row.email, "captain")}
                      aria-label="list-option"
                      className="hover:bg-[#F9F9FA] transition-colors cursor-pointer h-8 lg:h-9 flex items-center px-2 rounded-[7px] gap-2 text-black font-medium"
                    >
                      Captain
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default TableRow;
