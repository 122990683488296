import React from "react";
import Header2 from "../../layout/Header2";
import Table from "./Table";
import Footer from "../../layout/Footer";

export const PER_PAGE = 10;

const VoterIdLookup = () => {
  return (
    <>
      <Header2 />

      <div className="w-full flex justify-center mt-[110px] xs:mt-[120px] font-mont mb-10 xs:mb-12 sm:mb-14 lg:mb-20">
        <div className="w-[90%] max-w-[1400px] flex flex-col gap-8">
          <p className="text-lg font-medium">
            You are accessing the Harris County voter database, which
            exclusively contains records of registered voters in Harris County.
            No voter data from other counties is available in this system.
          </p>

          <Table />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default VoterIdLookup;
