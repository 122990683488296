import React, { useState } from "react";
import Header1 from "../../layout/Header1";
import Input from "../../components/Input";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button";

const CAPITAL_REGEX = /[A-Z]/;
const NUMBER_REGEX = /[0-9]/;
const SPECIAL_CHAR_REGEX = /[!@#$%^&*]/;

const defaultFormData = {
  newPassword: { value: "", isValid: false, message: "" },
  confirmNewPassword: { value: "", isValid: false, message: "" },
};

const Main = () => {
  const { token } = useParams();

  const [formData, setFormData] = useState(defaultFormData);

  const [buttonState, setButtonState] = useState(false);

  const navigate = useNavigate();

  const isValidated = Object.keys(formData).every(
    (name) => formData[name].isValid
  );

  const validateInput = (name, value, formData) => {
    if (name === "newPassword") {
      if (!CAPITAL_REGEX.test(value))
        return {
          isValid: false,
          message: "Password must contain at least one capital letter",
        };

      if (!NUMBER_REGEX.test(value))
        return {
          isValid: false,
          message: "Password must contain at least one number",
        };

      if (!SPECIAL_CHAR_REGEX.test(value))
        return {
          isValid: false,
          message: "Password must contain at least one special character",
        };

      if (value.length < 8)
        return {
          isValid: false,
          message: "Password must be at least 8 characters long",
        };

      return { isValid: true, message: "" };
    } else if (name === "confirmNewPassword") {
      if (value === "" || value !== formData.newPassword.value)
        return { isValid: false, message: "Passwords do not match" };

      return { isValid: true, message: "" };
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const { isValid, message } = validateInput(name, value, formData);

    setFormData((formData) => ({
      ...formData,
      [name]: { isValid, value, message },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidated) return;

    setButtonState("loading");

    try {
      const response = await axios.post(
        process.env.REACT_APP_RESET_PASSWORD_API_URL,
        {
          purpose: "reset-password",
          token,
          new_password: formData.newPassword.value,
        }
      );

      localStorage.setItem("jwt-token", response.data.token);
      toast.success("Password successfully reset!");
      navigate("/dashboard");
    } catch (error) {
      console.error("There was an error submitting the data!", error);
    }

    setButtonState("");
    setFormData(defaultFormData);
  };

  return (
    <>
      <Header1 />

      <div className="flex-1 flex justify-center items-center w-full">
        <div
          className="w-[90%] max-w-[450px] rounded-md p-4 flex flex-col items-center gap-3"
          style={{
            boxShadow:
              "-1px 0px 7px -1px #00000010, 0px -1px 7px -1px #00000010, 1px 0px 7px -1px #00000010, 0px 1px 7px -1px #00000010",
          }}
        >
          <h1 className="text-2xl font-semibold mb-1 leading-[1]">
            Set Password
          </h1>

          <p className="text-sm leading-[1.4]">
            Password must be at least 8 characters long, include one capital
            letter, one number, and one special character (!@#$%^&*).
          </p>

          <form onSubmit={handleSubmit} className="w-full flex flex-col gap-3">
            <Input
              variant="password"
              name="newPassword"
              placeholder={"Enter New Password"}
              onChange={handleChange}
              value={formData["newPassword"].value}
              message={formData["newPassword"].message}
              isValid={formData["newPassword"].isValid}
            />

            <Input
              variant="password"
              name="confirmNewPassword"
              placeholder="Confirm New Password"
              onChange={handleChange}
              value={formData["confirmNewPassword"].value}
              message={formData["confirmNewPassword"].message}
              isValid={formData["confirmNewPassword"].isValid}
            />

            <Button
              state={buttonState}
              disabled={!isValidated || buttonState === "loading"}
              className="h-[52px] text-xl"
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Main;
