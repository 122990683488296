import React, { useState } from "react";
import useDebounce from "../../hooks/useDebounce";

const SearchFilter = ({ updateFilters, value, colKey, debounce }) => {
  const [inputValue, setInputValue] = useState(value);

  useDebounce(
    () => {
      updateFilters(colKey, inputValue);
    },
    [inputValue],
    debounce ? 500 : 0,
    true
  );

  return (
    <td
      className={`h-[52px] py-1.5 first:pl-4 filter filter-dropdown filter-${colKey}`}
    >
      <input
        placeholder="Search"
        value={inputValue}
        name={colKey}
        onChange={(e) => setInputValue(e.target.value)}
        className="rounded-md h-full w-full border px-2 text-[13px] lg:text-[15px] focus-visible:outline-cherry focus-visible:outline-2 focus-visible:outline-double"
      />
    </td>
  );
};

export default SearchFilter;
