import React, { useEffect, useState } from "react";
import Main from "./Main";
import Header2 from "../../layout/Header2";
import Footer from "../../layout/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "../../components/Loading";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("jwt-token");

    axios
      .post(
        process.env.REACT_APP_DASHBOARD_STATS_API_URL,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        localStorage.setItem("jwt-token", response.data.token);
        setDashboardData(response.data.message["Overall"]);

        // setDashboardData({
        //   totalVoters: response.data.message["Overall"]["Total Voters"],
        //   totalVotes: response.data.message["Overall"]["Total Votes"],
        //   percentageVoted: response.data.message["Overall"]["Percentage Voted"],
        // });
      })
      .catch((error) => {
        console.error("There was an error!", error);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("jwt-token");
          navigate("/login");
        } else {
          setDashboardData({ error: "Failed to load dashboard data" });
        }
      });
  }, [navigate]);

  return (
    <>
      <Header2 />
      {dashboardData ? (
        <Main dashboardData={dashboardData} />
      ) : (
        <div className="flex justify-center items-center flex-1">
          <Loading />
        </div>
      )}
      <Footer />
    </>
  );
};

export default Dashboard;
