import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../components/Loading";
import { useLoginForm } from "../../App";
import { useNavigate } from "react-router-dom";

const defaultFormData = { email: "" };

const GetInvolved = () => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);
  const [agreed, setAgreed] = useState(false);
  const navigate = useNavigate();
  const { setLoginForm } = useLoginForm();

  const handleChange = (e) => {
    if (buttonLoading) return;
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    if (buttonLoading) return;
    setAgreed(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (buttonLoading) return;

    if (!formData.email) {
      toast.error("Email is required!");
      return;
    }

    const email = formData.email.toLowerCase().trim();

    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      toast.error("Invalid email address!");
      return;
    }
    if (!agreed) {
      toast.error("You must agree to the terms!");
      return;
    }

    setButtonLoading(true);

    axios
      .post(process.env.REACT_APP_EMAIL_SIGNUP_API_URL, {
        email,
        purpose: "send_email_verification_link",
      })
      .then((response) => {
        setButtonLoading(false);

        if (response.data.status === 'active') {
          setLoginForm({ email });
          navigate("/login");
          toast.info("You have an active account. Redirecting to login.");
        } else if (response.data.status === 'waiting') {
          toast.info(response.data.message);
        } else {
          toast.success(response.data.message);
        }

        setFormData(defaultFormData);
      })
      .catch((error) => {
        setButtonLoading(false);

        if (error.response) {
          if (error.response.status === 400) {
            if (error.response.data.message.includes("invalid")) {
              toast.error("The email address appears to be invalid. Please check and try again.");
            } else {
              toast.error(error.response.data.message);
            }
          } else if (error.response.status === 500) {
            toast.error("An error occurred on our end. Please try again later or contact support.");
          } else {
            toast.error(error.response.data.message || "An unexpected error occurred.");
          }
        } else if (error.request) {
          toast.error("No response received from the server. Please check your internet connection.");
        } else {
          toast.error("An error occurred while sending your request. Please try again.");
        }

        setFormData(defaultFormData);
      });
  };

  return (
    <>
      <div
        id="get-involved"
        className="bg-cherry text-white font-fira flex justify-center text-center pt-10 sm:pt-[50px] md:pt-[70px] mt-[90px]"
      >
        <div className="justify-center items-center flex flex-col gap-2 sm:gap-3 lg:gap-4 tracking-[0.6px] w-[90%] max-w-[1000px]">
          <h2 className="text-[32px] xs:text-[36px] sm:text-[42px] md:text-[46px] lg:text-[52px] font-black leading-[1.1]">
            Join Us
          </h2>

          <p className="text-lg xs:text-xl lg:text-[24px] font-medium leading-[1.5] max-w-[470px]">
            Get involved today to empower Houston voters and drive positive change in
            our community.
          </p>

          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col items-center gap-6 xs:gap-10 mt-5 sm:mt-8 md:mt-10 lg:mt-12"
          >
            <div className="flex flex-col items-start gap-4 w-full font-mont">
              <div className="w-full grid grid-cols-3 flex-col md:flex-row gap-3 lg:gap-4 text-base">
                <input
                  type="text"
                  name="email"
                  id="get-involved-email"
                  placeholder="Email"
                  disabled={buttonLoading}
                  className="border-2 border-white text-black bg-white bg-transparent h-[54px] px-5 rounded-xl w-full outline-none disabled:cursor-not-allowed col-span-3 font-semibold"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="lg:mx-3 sm:space-x-0.5 lg:space-x-1.5 text-start flex items-center text-lg">
                <input
                  type="checkbox"
                  name="agreed"
                  className="checkbox checkbox-white disabled:cursor-not-allowed"
                  checked={agreed}
                  disabled={buttonLoading}
                  onChange={handleCheckboxChange}
                  id="consent"
                />
                <label
                  htmlFor="consent"
                  className="text-sm sm:text-base cursor-pointer select-none translate-y-[-1px]"
                >
                  By checking this box, you agree to receive automated
                  communications from us.
                </label>
              </div>
            </div>

            <div className="flex gap-2">
              <button
                type="submit"
                disabled={buttonLoading}
                className="min-w-[170px] text-cherry bg-white font-extrabold font-mont tracking-[-0.02em] px-[22px] py-3 disabled:cursor-not-allowed disabled:bg-opacity-90"
              >
                {buttonLoading ? (
                  <Loading size={24} className="mx-auto h-full" />
                ) : (
                  "JOIN"
                )}
              </button>
            </div>
          </form>

          <img
            src="/high-five.png"
            alt="high five"
            className="mt-10 xs:mt-14"
            width={419}
            height={248}
          />
        </div>
      </div>
    </>
  );
};

export default GetInvolved;