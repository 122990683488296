import React, { useState, useEffect, useRef } from "react";
import TableRow from "./TableRow";
import Filters from "../../components/table/Filters";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../../components/table/Pagination";
import TableHead from "./TableHead";
import axios from "axios";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";
import VoterIdLookupSteps from "../../components/steps/VoterIdLookupSteps";
import ToggleColumns from "../../components/table/ToggleColumns";

const defaultColumns = [
  {
    key: "last_name",
    text: "Last Name",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "first_name",
    text: "First Name",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "middle_name",
    text: "Middle Name",
    isActive: false,
    filter: { type: "search", value: "" },
  },
  {
    key: "voter_id",
    text: "Voter ID",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "house_number",
    text: "House Number",
    isActive: false,
    filter: { type: "search", value: "" },
  },
  {
    key: "street_name",
    text: "Street Name",
    isActive: true,
    filter: { type: "search", value: "" },
  },
  {
    key: "zipcode",
    text: "Zip Code",
    isActive: false,
    filter: { type: "search", value: "" },
  },
  {
    key: "age",
    text: "Age",
    isActive: false,
    filter: { type: "search", value: "" },
  },
  {
    key: "city",
    text: "City",
    className: "capitalize",
    isActive: false,
    filter: { type: "search", value: "" },
  },
  {
    key: "precinct",
    text: "Precinct",
    isActive: false,
    filter: { type: "search", value: "" },
  },
];

const defaultSort = { column: null, order: null };

const tempData = [
  {
    age: 69,
    city: "spring",
    first_name: "Mary",
    full_address: "16510 dover mills dr spring, tx 77379",
    gender: "female",
    house_number: "16510",
    last_name: "Langlinais",
    middle_name: "annette",
    ohps_rank: "TopTier",
    precinct: "514",
    primary_voting_history: "R,R,R,R,R",
    street_name: "Dover Mills Dr",
    voted: "0",
    voter_id: 1000003483,
    zipcode: "77379",
  },
];

const Table = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredList, setFilteredList] = useState([]);

  const [columns, setColumns] = useState(() => {
    const memory = JSON.parse(localStorage.getItem("voter-id-lookup-memory"));
    return memory?.columns || defaultColumns;
  });

  const [sort, setSort] = useState(() => {
    const memory = JSON.parse(localStorage.getItem("voter-id-lookup-memory"));
    return memory?.sort || defaultSort;
  });

  const [perPage, setPerPage] = useState(() => {
    const memory = JSON.parse(localStorage.getItem("voter-id-lookup-memory"));
    return memory?.perPage || 10;
  });

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [fetchingAddVoterToList, setFetchingAddVoterToList] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isShowingTutorial, setIsShowingTutorial] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  useEffect(() => {
    console.log("Current state:", {
      filteredList,
      sort,
      columns,
      totalPages,
      currentPage,
      fetchingAddVoterToList,
      isFetchingData,
      isShowingTutorial,
      hasSearched,
    });
  }, [
    filteredList,
    sort,
    columns,
    totalPages,
    currentPage,
    fetchingAddVoterToList,
    isFetchingData,
    isShowingTutorial,
    hasSearched,
  ]);

  const addVoterToList = async (voterId) => {
    setFetchingAddVoterToList(voterId);

    try {
      const response = await axios.post(
        process.env.REACT_APP_ADD_NEW_ROW_API_URL,
        { voter_id: String(voterId), purpose: "add_row_only" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt-token")}`,
          },
        }
      );

      setFilteredList((prev) =>
        prev.map((row) =>
          row.voter_id === voterId ? { ...row, isDisabled: true } : row
        )
      );

      localStorage.setItem("jwt-token", response.data.token);
      toast.success(`Voter added to list`);
    } catch (error) {
      console.error("Error adding voter to list:", error);
      if (
        ["Token has expired", "Unauthorized", "Invalid token"].includes(
          error.response?.data
        )
      ) {
        console.log("Token invalid, navigating to login");
        navigate("/login");
      }

      if (
        error.response.data ===
        "A user with this name already exists in your list"
      ) {
        setFilteredList((prev) =>
          prev.map((row) =>
            row.voter_id === voterId ? { ...row, isDisabled: true } : row
          )
        );

        toast.error(error.response.data);
      }
    }

    setFetchingAddVoterToList(false);
  };

  const fetchFilteredList = async (page = 1) => {
    console.log("Fetching filtered list for page:", page);
    setSearchParams({ page: page.toString() });
    setIsFetchingData(true);
    setCurrentPage(page);
    const filters = getAppropriateFiltersFormat();

    try {
      console.log(
        "Sending request to:",
        process.env.REACT_APP_VOTER_ID_LOOKUP_API_URL
      );
      console.log("Request payload:", {
        page: page.toString(),
        sort,
        filters,
        per_page: perPage,
      });
      const response = await axios.post(
        process.env.REACT_APP_VOTER_ID_LOOKUP_API_URL,
        {
          page: page.toString(),
          sort,
          filters,
          per_page: perPage,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt-token")}`,
          },
        }
      );

      console.log("API response:", response.data);
      localStorage.setItem("jwt-token", response.data.token);
      setFilteredList(response.data.items);
      setTotalPages(response.data.total_pages);
      setHasSearched(true);
      console.log("Updated state:", {
        filteredList: response.data.items,
        totalPages: response.data.total_pages,
      });
    } catch (error) {
      console.error("Error fetching filtered list:", error);
      if (
        ["Token has expired", "Unauthorized", "Invalid token"].includes(
          error.response?.data
        )
      ) {
        console.log("Token invalid, navigating to login");
        navigate("/login");
      }
    } finally {
      setIsFetchingData(false);
    }
  };

  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else {
      localStorage.setItem(
        "voter-id-lookup-memory",
        JSON.stringify({ columns, sort, perPage })
      );
    }
  }, [
    ...columns.map((c) => c.isActive),
    ...columns.map((c) => c.filter.value),
    sort,
    perPage,
  ]);

  const firstRenderRef2 = useRef(true);

  useEffect(() => {
    if (firstRenderRef2.current) {
      firstRenderRef2.current = false;
      return;
    } else {
      fetchFilteredList(1);
    }
  }, [sort]);

  function getAppropriateFiltersFormat() {
    console.log("Getting appropriate filters format");
    const filters = columns.reduce((acc, col) => {
      if (col.filter.value) {
        if (col.key === "voted") {
          acc[col.key] = col.filter.value === "Voted" ? "1" : "0";
        } else acc[col.key] = col.filter.value.toLowerCase().trim();
      }
      return acc;
    }, {});
    console.log("Filters format:", filters);
    return filters;
  }

  const addedTempData = useRef(false);

  const [filtersKey, setFiltersKey] = useState(0);

  const resetColumns = () => {
    setColumns(
      columns.map((col) => ({
        ...col,
        filter: { ...col.filter, value: "" },
      }))
    );
  };

  const resetFilters = () => {
    localStorage.removeItem("voter-id-lookup-memory");

    resetColumns();
    setPerPage(10);
    setSort(defaultSort);

    setFiltersKey((prev) => (prev += 1));
  };

  return (
    <>
      <div className="flex flex-col w-full gap-3 sm:gap-4">
        <div className="flex flex-col xs:flex-row items-end xs:items-center justify-end gap-2">
          <VoterIdLookupSteps
            isShowing={isShowingTutorial}
            setIsShowing={setIsShowingTutorial}
            setFilteredList={setFilteredList}
            addedTempData={addedTempData}
          />

          <button
            className="flex items-center font-semibold gap-1 md:gap-3 border h-11 px-2 md:px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px]"
            onClick={resetFilters}
          >
            Reset Filters
          </button>

          <button
            disabled={isFetchingData}
            className="flex items-center font-semibold gap-1 md:gap-3 border h-11 px-2 md:px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px]"
            onClick={() => {
              setIsShowingTutorial(true);
              setFilteredList((prev) => {
                if (prev.length > 0) return prev;
                addedTempData.current = true;
                return tempData;
              });
            }}
          >
            Page Helper
          </button>

          <ToggleColumns
            id="voter-id-toggle-columns-button"
            columns={columns}
            setColumns={setColumns}
            sort={sort}
            setSort={setSort}
          />
        </div>

        <div className="overflow-x-auto w-full border border-b-0 rounded-md">
          <table
            id="voter-id-lookup-table"
            className="w-full [&_td]:px-2 [&_th]:px-2"
          >
            <TableHead
              columns={columns}
              setColumns={setColumns}
              sort={sort}
              setSort={setSort}
              isFetchingData={isFetchingData}
            />
            <tbody>
              <Filters
                key={filtersKey}
                columns={columns}
                setColumns={setColumns}
                isFetchingData={isFetchingData}
                fetchFilteredList={() => fetchFilteredList(1)}
                submitBtn
                searchDependencies={[
                  ...columns.map((c) => c.filter.value),
                  sort,
                  perPage,
                ]}
              />
              {filteredList.map((row, rowIndex) => {
                return (
                  <TableRow
                    key={rowIndex}
                    row={row}
                    columns={columns}
                    addVoterToList={addVoterToList}
                    fetchingAddVoterToList={fetchingAddVoterToList}
                    index={rowIndex}
                  />
                );
              })}
            </tbody>
          </table>
        </div>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          fetchFilteredList={fetchFilteredList}
          isFetchingData={isFetchingData}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </div>
    </>
  );
};

export default Table;
