import React, { useState } from "react";
import Button from "../../components/Button";
import axios from "axios";
import { toast } from "react-toastify";

const ConfirmDeleteAccount = ({ close, email }) => {
  const [buttonState, setButtonState] = useState("");

  const deleteAccount = async () => {
    setButtonState("loading");

    try {
      const response = await axios.post(
        process.env.REACT_APP_ADMIN_API_URL,
        {
          purpose: "remove_account_info",
          email: email
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt-token")}`,
          },
        }
      );
      console.log(response);
      toast.success("Account deleted successfully");
      close();
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
      setButtonState("");
    }
  };

  return (
    <div className="fixed z-[51] inset-0 isolate flex justify-center items-center">
      <div
        className="absolute inset-0 bg-black bg-opacity-30 cursor-pointer -z-10"
        onClick={close}
      ></div>

      <div className="w-[90%] max-w-[500px] bg-white flex flex-col rounded-xl relative p-5">
        <div className="space-y-1">
          <h6 className="text-xl xs:text-2xl text-[#323232] font-semibold font-mont !leading-[1.3]">
            Delete Account
          </h6>
          <p className="text-[#727272]">
            Please confirm that you want to delete this account.
          </p>
        </div>

        <div className="flex gap-2 mt-4 ml-auto">
          <Button
            className="bg-transparent !text-black !border-black"
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            state={buttonState}
            disabled={buttonState === "loading"}
            onClick={deleteAccount}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteAccount;