import React from "react";

const Main = ({ dashboardData }) => {
  return (
    <div className="flex justify-center w-full font-fira mt-[100px] sm:mt-[110px] xl:mt-[130px] items-center mb-10 xs:mb-12 sm:mb-14 lg:mb-20">
      <div className="flex flex-col gap-16 w-[90%] max-w-[1400px] items-center">
        <div className="flex gap-y-8 gap-x-8 xl:gap-x-10 justify-center flex-wrap">
          {Object.keys(dashboardData).map((key, index) => {
            const isPercentageStat = dashboardData[key].includes("%");

            return !isPercentageStat ? (
              <div
                key={index}
                className="w-[320px] flex flex-col items-center justify-center text-[27px] xl:text-3xl p-5 text-center aspect-square border-[20px] lg:border-[24px] border-cherry rounded-full gap-2 lg:gap-3"
              >
                <span className="leading-[1]">{key}</span>
                <span className="leading-[1] font-semibold">
                  {dashboardData[key]}
                </span>
              </div>
            ) : (
              <div
                key={index}
                className="w-[320px] flex flex-col items-center justify-center text-[27px] xl:text-3xl p-5 text-center aspect-square border-[20px] lg:border-[24px] border-transparent rounded-full gap-2 lg:gap-3 relative"
              >
                <div
                  style={{
                    background: !dashboardData
                      ? "conic-gradient(#C82446 0% 100%)"
                      : `conic-gradient(#eaeaea 0% ${
                          100 - dashboardData[key].slice(0, -1)
                        }%, #C82446 ${
                          100 - dashboardData[key].slice(0, -1)
                        }% 100%)`,
                  }}
                  className="absolute -inset-[24px] rounded-full -z-10 transition-all"
                ></div>
                <div className="absolute inset-0 bg-white rounded-full -z-10"></div>

                <span className="leading-[1]">{key}</span>
                <span className="leading-[1] font-semibold">
                  {dashboardData[key]}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Main;
