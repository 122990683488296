import axios from "axios";
import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import readXlsxFile from "read-excel-file";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button";

const CHUNK_SIZE = 100;

const Upload = ({
  setFilteredList,
  close,
  setTotalPages,
  currentPage,
  perPage,
  currentSort,
  currentFilters,
}) => {
  const [file, setFile] = useState(null);
  const [buttonState, setButtonState] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [sheets, setSheets] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState("");
  const [sheetError, setSheetError] = useState(false);

  const navigate = useNavigate();

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const handleFileSubmit = async (e) => {
    e.preventDefault();

    if (file) {
      setButtonState("loading");
      setUploadProgress(0);

      let data = [];

      await new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          complete: (results) => {
            const filteredData = results.data.filter((row) => {
              if (Object.keys(row).length === 0) return false; // if empty row, somehow
              if (Object.keys(row).every((key) => !row[key])) return false; // if all keys are empty
              return true;
            });

            data = filteredData;
            console.log("CSV data:", data);
            console.log("Number of rows:", data.length);
            resolve();
          },
          error: (error) => {
            console.error("Error parsing CSV file:", error);
            toast.error(
              "Failed to parse CSV file. Please check the file format and try again."
            );
            setButtonState("");
            reject(error);
          },
        });
      });

      try {
        const totalChunks = Math.ceil(data.length / CHUNK_SIZE);
        let processedChunks = 0;
        let totalRowsUploaded = 0;

        for (let i = 0; i < totalChunks; i++) {
          const chunk = data.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
          console.log(
            `Uploading chunk ${i + 1}/${totalChunks}, size: ${chunk.length}`
          );

          const response = await axios.post(
            process.env.REACT_APP_LOAD_DATA_TABLE_API_URL,
            {
              data: chunk,
              purpose: "import_data",
              page: currentPage,
              per_page: perPage,
              sort: currentSort,
              filters: currentFilters,
              total_rows: data.length,
              chunk_number: i + 1,
              total_chunks: totalChunks,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt-token")}`,
              },
            }
          );

          processedChunks++;
          totalRowsUploaded += chunk.length;
          const progress = Math.round((processedChunks / totalChunks) * 100);
          setUploadProgress(progress);

          console.log(`Chunk ${i + 1}/${totalChunks} uploaded`);
          console.log("Server response:", response.data);
          console.log(`Total rows uploaded so far: ${totalRowsUploaded}`);

          localStorage.setItem("jwt-token", response.data.token);

          if (i === totalChunks - 1) {
            setFilteredList(response.data.items);
            setTotalPages(response.data.total_pages);
          }
        }

        console.log(
          `Upload complete. Total rows uploaded: ${totalRowsUploaded}`
        );
        toast.success(
          `Data successfully imported! ${totalRowsUploaded} rows uploaded.`
        );
        resetForm();
        close();
      } catch (error) {
        console.error("API error:", error.response || error);
        if (error.response && error.response.status === 504) {
          toast.info(
            "Import is taking longer than expected. The page will refresh shortly."
          );
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } else if (
          error.response &&
          (error.response.data === "Token has expired" ||
            error.response.data === "Unauthorized" ||
            error.response.data === "Invalid token")
        ) {
          navigate("/login");
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          console.error("Error uploading data:", error);
          toast.error(
            "Error uploading data. Please try again or contact support if the issue persists."
          );
        }
      } finally {
        setButtonState(false);
        setUploadProgress(0);
      }
    }
  };

  const resetForm = () => {
    setFile(null);
    setSheets([]);
    setSelectedSheet("");
    setSheetError(false);
  };

  const download = () => {
    const csvData = [["First Name", "Last Name", "Voter ID"]];
    const csvContent = csvData.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Template_Voter_List.csv";

    link.click();

    URL.revokeObjectURL(link.href);
  };

  return (
    <form
      onSubmit={handleFileSubmit}
      className="p-4 xs:p-5 flex flex-col gap-4"
    >
      <div className="space-y-0.5">
        <h6 className="text-[#323232] font-semibold text-lg">Instructions</h6>
        <p className="text-[#969696] leading-[1.35] text-lg">
          Upload a file containing names to search for matching records in the
          database. <br />
          This program only accepts{" "}
          <span className="text-[#323232] font-semibold">csv</span> files
        </p>
      </div>

      <div>
        <div className="flex items-center justify-center w-full">
          <label
            htmlFor="file-upload"
            className="flex flex-col items-center justify-center w-full h-[115px] border-2 border-[#ccc] border-dashed rounded-lg cursor-pointer bg-[#f3f3f3] hover:bg-[#eaeaea] transition-all"
          >
            <div className="flex flex-col items-center justify-center gap-1.5">
              <p className="text-[#969696] font-medium">Click to upload</p>

              <svg
                className="size-8 text-[#969696]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
            </div>
            <input
              id="file-upload"
              type="file"
              accept=".csv"
              className="hidden"
              onChange={handleFileUpload}
            />
          </label>
        </div>

        {file && (
          <div className="flex items-center gap-2 mt-3.5">
            <img src="/document.svg" alt="document" className="size-6" />
            <span className="font-fira">{file?.name}</span>
            <button
              type="button"
              onClick={() => setFile(null)}
              className="border border-black p-1.5 hover:bg-[#F9F9FA] transition-colors rounded-md ml-auto"
            >
              <img src="/cross.svg" alt="cross" className="size-4" />
            </button>
          </div>
        )}

        {sheets.length > 0 && (
          <div className="flex flex-col gap-2">
            <label htmlFor="sheet-select">Select Sheet to Import:</label>
            <select
              id="sheet-select"
              value={selectedSheet}
              onChange={(e) => {
                setSelectedSheet(e.target.value);
                setSheetError(false);
              }}
              className={`border rounded-md p-2 ${
                sheetError ? "border-red-500" : ""
              }`}
            >
              <option value="">Select a sheet</option>
              {sheets.map((sheet) => (
                <option key={sheet.name} value={sheet.name}>
                  {sheet.name}
                </option>
              ))}
            </select>
            {sheetError && (
              <p className="text-red-500 text-sm">
                Please select a sheet to import
              </p>
            )}
          </div>
        )}
      </div>

      {buttonState === "loading" ? (
        <div className="flex flex-col justify-end items-center h-[106px]">
          <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
            <div
              className="bg-blue-600 h-2.5 rounded-full"
              style={{ width: `${uploadProgress}%` }}
            ></div>
          </div>
          <p>Uploading: {uploadProgress}%</p>
        </div>
      ) : (
        <div className="flex flex-col gap-2.5">
          <button
            type="button"
            onClick={download}
            className="h-[48px] font-semibold text-[#323232] border rounded-lg flex items-center justify-center gap-2"
          >
            <img src="/import.svg" alt="add" className="h-6" />
            <span>Download Template CSV</span>
          </button>

          <Button
            type="submit"
            disabled={!file || buttonState === "loading"}
            className="h-[48px] text-lg !rounded-lg"
          >
            Submit
          </Button>
        </div>
      )}
    </form>
  );
};

export default Upload;
