import React from "react";
import Header1 from "../../layout/Header1";
import Main from "./Main";

const ForgotPassword = () => {
  return (
    <>
      <Header1 />
      <Main />
    </>
  );
};

export default ForgotPassword;
