import React from "react";
import Dropdown from "../../components/Dropdown";

const ToggleColumns = ({ id, columns, setColumns, sort, setSort }) => {
  const toggleColumn = (key) => {
    setColumns((prev) => {
      const newCols = prev.map((col) => {
        if (col.key === key)
          return {
            ...col,
            isActive: !col.isActive,
            filter: { ...col.filter, value: "" }, // resets filter value
          };
        return col;
      });

      if (sort.column === key) setSort({ column: null, order: null }); // resets sort if any

      return newCols;
    });
  };

  return (
    <Dropdown
      className="relative"
      dropdownClassName="min-w-[108%]"
      button={
        <div
          id={id}
          className="flex items-center font-semibold gap-1 xs:gap-3 border h-11 px-2 xs:px-3 hover:bg-[#F9F9FA] transition-colors rounded-md text-sm lg:text-[15px]"
        >
          Toggle Columns
          <img
            src="/chevron-down.svg"
            alt="open"
            className="translate-y-[1px]"
          />
        </div>
      }
      listItems={columns.map((col) => (
        <li
          key={col.key}
          aria-label="list-option"
          className="hover:bg-[#F9F9FA] transition-colors cursor-pointer h-9 flex items-center px-2 font-medium text-sm rounded-[7px] gap-2"
          onClick={() => toggleColumn(col.key)}
        >
          {col.isActive ? (
            <img src="/check.svg" alt="check" className="w-4" />
          ) : (
            <div className="w-4" />
          )}
          <span>{col.text}</span>
        </li>
      ))}
    />
  );
};

export default ToggleColumns;
